import React, { useState, useEffect } from "react";
import "./PageCarousel.less";

const prefix = "page-carousel"

const PageCarousel = ({ elements, elementWidth, elementHeight, pageNum, onChange}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    if(currentIndex === 0) return;
    setCurrentIndex((prevIndex) => (prevIndex - 1) * pageNum % elements.length);
    onChange?.((currentIndex - 1) * pageNum % elements.length)
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) * pageNum % elements.length); 
    onChange?.((currentIndex + 1) * pageNum % elements.length)
  };

  const sliderStyle = {
    transform: `translateX(-${currentIndex * elementWidth}rem)`,
    transition: "transform 0.5s ease-in-out",
    width: `${elements.length * elementWidth}rem`,
  };

  return (
    <div className={prefix}>
      <div className={`${prefix}-conatiner`} style={{ width: `${elementWidth * pageNum}rem`, height: `${elementHeight}rem`, overflow: "hidden" }}>
        <div className="slider" style={sliderStyle}>
          {Array.isArray(elements) && elements.map((element, index) => (
            <div key={index} className="slide" style={{ width: `${elementWidth}rem`, height: `${elementHeight}rem` }}>
              {element}
            </div>
          ))}
        </div>
      </div>
      <div className={`${prefix}-action-group`}>
        <img className='prev' src="https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/page-carousel-icon.png" onClick={handlePrev} />
        <div>{currentIndex + 1} / <span className={`${prefix}-page-number`}>{elements?.length}</span></div>
        <img className='next' src="https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/page-carousel-icon.png" onClick={handleNext} />
      </div>
    </div>
  );
};

export default PageCarousel;
