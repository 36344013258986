/**
 * 重点报道
 */
import React, { useEffect, useState } from 'react';
import ModalGroup from '@/components/ModalGroup'
import KeyReportsKeyThemesListModal from './components/KeyReportsKeyThemesListModal'
import KeyReportsKeyThemesModal from './components/KeyReportsKeyThemesModal'
import KeyReportsOtherThemesListModal from './components/KeyReportsOtherThemesListModal'
import KeyReportsOtherThemesModal from './components/KeyReportsOtherThemesModal'


const formatKeyReportsKeyThemesListData = (list) => {
  if (!Array.isArray(list)) return []
  let data = []
  list.map(({ title, pdcont, num, zdContList }) => {
    const current = zdContList?.[0] ?? {}

    if (title !== "测试数据" && current?.pdcont?.length > 0) {
      data.push({
        title,
        count: num,
        content: zdContList,
      })
    }
  })
  return data
}

const formatKeyReportsOtherThemesListData = (list) => {
  if (!Array.isArray(list)) return []

  let data = []
  list.map(({ zdId, pdcont, titleContent }) => {
    if (titleContent !== "测试数据") {
      data.push({
        id: zdId,
        title: titleContent,
        list: pdcont
      })
    }
  })
  return data
}

let items = []

const KeyReportsModal = ({ onClose, data }) => {
  const { imporZd01, imporZdContList } = data
  const keyReportsKeyThemesListData = formatKeyReportsKeyThemesListData(imporZd01)
  const KeyReportsOtherThemesListData = formatKeyReportsOtherThemesListData(imporZdContList)
  const [details, setDetails] = useState([])

  const [prePage, setPrePage] = useState("")

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [onShow, setOnShow] = useState('')
  const [isDetail, setIsDetail] = useState('')

  items = [
    {
      id: "key-report-key-themes-list",
      title: "重点主题话题",
      element: <KeyReportsKeyThemesListModal list={keyReportsKeyThemesListData} showDetail={({ title, content, list }) => {
        setDetails(list)
        setTitle(title)
        setContent(content)
        setPrePage("key-report-key-themes-list")
        onChangeShow("key-report-key-themes")
      }} />,
      isEmpty: keyReportsKeyThemesListData?.length === 0,
    },
    {
      id: "key-report-key-themes",
      title: "重点主题话题",
      element: <KeyReportsKeyThemesModal content={content} />,
      isDetail: true,
      prePage: 'key-report-key-themes-list'
    },
    {
      id: "key-report-other-themes-list",
      title: "当日其他主题宣传",
      element: <KeyReportsOtherThemesListModal list={KeyReportsOtherThemesListData} showDetail={({ title, content, list }) => {
        setDetails(list)
        setTitle(title)
        setContent(content)
        setPrePage("key-report-other-themes-list")
        onChangeShow("key-report-other-themes")
      }}
      />,
      isEmpty: KeyReportsOtherThemesListData?.length === 0,
    },
    {
      id: "key-report-other-themes",
      title: "当日其他主题宣传",
      element: <KeyReportsOtherThemesModal list={details} content={content} />,
      isDetail: true,
      prePage: 'key-report-other-themes-list'
    }
  ]

  const current = items.find(item => !item.isEmpty && !item.isHidden)

  useEffect(() => {
    setTitle(current?.title || '')
    setContent(current?.content || '')
    setOnShow(current?.id)
    setIsDetail(current?.isDetail || '')
  }, [])

  const onChangeShow = (id) => {
    const item = items.find(item => item.id === id)
    setOnShow(id)
    setIsDetail(item.isDetail)

    if(!item || !item.prePage) {
      setTitle(item?.title)
      setContent(item?.content || '')
    }
  }

  return (
    <ModalGroup
      title={title}
      onShow={onShow}
      onClose={onClose}
      prePage={prePage}
      onShowDetail={(current, list = []) => {
        setDetails(list)
      }}
      onReturn={(current) => {
        setDetails([])
        onChangeShow(current)
      }}
      isDetail={isDetail}
      setOnShow={onChangeShow}
      items={items}
    />
  )
}
export default KeyReportsModal;