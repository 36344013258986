/**
 * 其他媒体检测：新闻专题
 */

import React, { useEffect, useState } from 'react';
import Card from '@/components/Card';
import ModalCard from '@/components/ModalCard';
import TypographyList from '@/components/TypographyList';

import "./index.less";

const prefix = 'other-media-news-topics-model'
const OtherMediaNewsTopicsModal = ({ pepolesDaily = [], xinhuaNews = [] }) => {
  const dataList = [
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/pepoles-daily-icon.png",
      source: "人民日报",
      content: pepolesDaily
    },
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/xinhua-news-icon.png",
      source: "新华社",
      content: xinhuaNews
    }
  ]
  return (
    <ModalCard>
      <div className={prefix}>
        {
          Array.isArray(dataList) && dataList.map(item =>
            <Card
              className={`${prefix}-card`}
              key={item.source}
              title={<div className={`${prefix}-card-title element-right-in`}>
                <div className={`${prefix}-card-source-icon`}>
                  <img alt='' src={item.icon} />
                </div>
                <div className={`${prefix}-card-source`}>{item.source}</div>
              </div>}
            >
              <TypographyList className={`${prefix}-typography`} type="bulleted" content={item.content} />
            </Card>)
        }
      </div>
    </ModalCard>
  )
}
export default OtherMediaNewsTopicsModal;