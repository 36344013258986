import React, { useEffect, useState } from 'react';

import './OutlineDailyDataView.less'
import classNames from 'classnames';
import { Popover } from 'antd';

const prefix = 'outline-daily-view'
const OutlineDataView = ({ width, name, number, unit, length, pos }) => {


  const isShowDot = (i, length) => {
    if (length === 3 && i === 1) {
      return true;
    }
    if (length === 2 && i === 0) {
      return true;
    }
    if (length === 4 && (i === 0 || i === 2)) {
      return true;
    }
  }

  const isShowBorder = (length === 3 && pos === 0) || (length === 4 && (pos === 0 || pos === 1));
  return (
    <div className={classNames(prefix, isShowBorder ? `${prefix}-border` : '')} style={{ width: `calc(${width})` }}>
      <div className={classNames(`${prefix}-container`, pos === 0 && length === 3 ? `${prefix}-fullrow` : '')}>
        <div className={`${prefix}-warp`}>
          <div className={`${prefix}-amount`}>{number}</div>
          <span className={`${prefix}-unit`}>{unit}</span>
        </div>
        <div className={`${prefix}-content`}>{name.length > 24 ? <Popover placement="bottom" content={name} trigger="hover">
          <>{name}</>
        </Popover> : name}</div>
      </div>
      <div className={`${prefix}-dot-warp`}>
        {
          isShowDot(pos, length) && <div className={`${prefix}-dot`}></div>
        }
      </div>
    </div>
  )
}
export default OutlineDataView;