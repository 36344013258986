import classNames from "classnames";
import React, { useState } from "react";

import "./ImageHover.less"

const prefix = "image-hover"
const ImageHoverEffect = ({ className, children }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <div
      className={classNames(prefix, className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`image ${hovered ? "hovered" : ""}`}>
        {children}
      </div>
    </div>
  );
}

export default ImageHoverEffect;
