import React from 'react';

import './Badge.less'

const prefix = 'badge'
const Badge = ({ children }) => {
  return (
    <span className={prefix}>
      {children}
    </span>
  )
}
export default Badge;