import request from '@/utils/request'

const domian = "https://cmsr.cpec.tv"
const ApiPath = "/mtzl-important-inteligence/important/impor"

const Apis = {
  MonthlyList: "/getCalenderByDateYm/",
  DailyDataById: "/h5/getById/",
  GetIdByTime: "/getIdByTime/"
}

export const getMonthlyList = async (month) => {
  return await request.post(`${domian}${ApiPath}${Apis.MonthlyList}${month}`)
}

export const getDailyDataById = (id) => {
  return request.get(`${domian}${ApiPath}${Apis.DailyDataById}${id}`)
}
export const getIdByTime = (id) => {
  return request.get(`${domian}${ApiPath}${Apis.GetIdByTime}${id}`)
}