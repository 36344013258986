import React from 'react';

import './Tabs.less'

const prefix = 'tabs'
const Tabs = ({ className, activeKey, formatLabel, items, keyInItem, childrenInItem, onChange, formatItem, itemHeight }) => {

  const handleClick = (item) => {
    typeof onChange === 'function' && onChange(item)
  }

  const labelList = []
  let itemList = []
  Array.isArray(items) && items.map((item, i) => {
    const key = item?.[`${keyInItem}`]
    if (typeof formatLabel === 'function') {
      labelList.push(<div onClick={() => handleClick(item?.[`${keyInItem}`])} className={key === activeKey ? `${prefix}-active-label element-top-in` : `${prefix}-label element-top-in`} key={key}>
        {formatLabel(item, item?.[`${keyInItem}`], i)}
      </div>)
    }
    if (typeof formatItem === 'function' && key === activeKey) {
      itemList = formatItem(item?.[`${childrenInItem}`], item, i) || []
    }
    return item;
  })
  return (
    <div className={`${prefix} ${className || ""}`}>
      <div className={`${prefix}-labels`}>
        {labelList}
      </div>
      <div className={`${prefix}-items`} style={{ height: itemHeight }}>
        {itemList}
      </div>
    </div>
  )
}
export default Tabs;