import React from 'react';

import './IconTypeCount.less'

const prefix = 'icon-type-count'
const IconTypeCount = ({ icon, name, count, className, animationDelay }) => {
  return (
    <div className={`${prefix}`}>
      <div
        className={`${prefix}-header ${className}`}
        style={{ animationDelay: animationDelay || '0.5s' }}
      >
        <img alt='' src={icon} />
        <span>{name}</span>
      </div>
      <div
        className={`${prefix}-content ${className}`}
        style={{ animationDelay: animationDelay || '0.5s' }}
      >
        {count}
        <span>条</span>
      </div>
    </div>
  )
}
export default IconTypeCount;