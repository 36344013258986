import axios from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { encryptDES } from '@/utils/des';
import { getDailyDataById, getIdByTime } from '@/services';

const formatDate = date => dayjs(date).format('YYYY-MM-DD');

const getDailyData = async (currentDay) => {
  const id = await getIdByTime(currentDay)
  const res = encryptDES(`${id}-ZSQWyqbg`)
  return await getDailyDataById(res)
}

const useDailyData = (currentDay = dayjs()) => {
  const [dailyData, setDailyData] = useState([]);
  const [cache, setCache] = useState({});

  useEffect(() => {
    const requests = [];
    for (let i = 0; i < 7; i++) {
      const date = dayjs(currentDay).subtract(i, 'day');
      const dateString = formatDate(date);

      const cachedResponse = cache[dateString];
      console.log('cachedResponse', cachedResponse)
      if (cachedResponse) {
        requests.push(Promise.resolve(cachedResponse));
      } else {
        const request = getDailyData(dateString);
        requests.push(request);
        request.then(response => {
          setCache({ ...cache, [dateString]: response });
        }).catch(error => console.log(error));
      }
    }


    Promise.all(requests)
      .then(responses => {
        let newCache = []

        const data = responses.map((response, i) => {
          const current = response?.dateStr ?? ""
          newCache = {
            ...newCache,
            [current]: { ...response }
          }
          return response;
        });
        setCache({ ...cache, ...newCache })
        setDailyData(data);
      })
      .catch(error => console.log(error));

  }, [currentDay]);

  return dailyData;
};

export default useDailyData;
