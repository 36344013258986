/**
 * 总台每日宣传要情-每日亮点
 */

import React, { useEffect } from 'react';
import ModalCard from '@/components/ModalCard';
import OutlineFlagshipPlatformItem from './components/OutlineFlagshipPlatformItem'

import './index.less'
import dayjs from 'dayjs';

const formatData = (weeks) => {
  if (!Array.isArray(weeks)) return {}
  const zgTotle = []
  const ytotle = []
  const gzsTotle = []
  const hqsTotle = []
  const hwTotle = []
  const twzsTotle = []
  weeks.map(item => {
    const { impormain = {}, dateStr } = item
    const date = dayjs(dateStr).subtract(1, 'd').format('DD日')
    zgTotle.push({
      date,
      value: Number(impormain?.zgTotle?.toString() || 0)
    })
    ytotle.push({
      date,
      value: Number(impormain?.ytotle?.toString() || 0)
    })
    gzsTotle.push({
      date,
      value: Number(impormain?.gzsTotle?.toString() || 0)
    })
    hqsTotle.push({
      date,
      value: Number(impormain?.hqsTotle?.toString() || 0)
    })
    hwTotle.push({
      date,
      value: Number(impormain?.hwTotle?.toString() || 0)
    })
    twzsTotle.push({
      date,
      value: Number(impormain?.twzsTotle?.toString() || 0)
    })
  })
  return {
    zgTotle: zgTotle.reverse(),
    ytotle: ytotle.reverse(),
    gzsTotle: gzsTotle.reverse(),
    hqsTotle: hqsTotle.reverse(),
    hwTotle: hwTotle.reverse(),
    twzsTotle: twzsTotle.reverse(),
  }
}

const prefix = 'outline-flagship-platform-modal'
const OutlineFlagshipPlatformModal = ({ zgTotle, ytotle, gzsTotle, hqsTotle, hwTotle, twzsTotle, weeks }) => {
  const weeksData = formatData(weeks)
  console.log('weeksData', formatData(weeks))
  const dataList = [
    {
      name: "综合频道",
      count: zgTotle,
      lineColor: 'l(180) 0:#4DFFDF 1:#4DA1FF',
      areaColor: 'l(90) 0:rgba(77, 255, 223, 1) 0% 1:rgba(77, 161, 255, 0.0001) 94.59%',
      data: weeksData?.zgTotle || null
    },
    {
      name: "新闻频道",
      count: ytotle,
      lineColor: 'l(180) 0:#01AAFE 1:#1F50FF',
      areaColor: 'l(90) 0:rgba(1, 144, 254, 1) 0% 1:rgba(2, 58, 255, 0.0001) 100%',
      data: weeksData?.ytotle || null
    },
    {
      name: "中国之声",
      count: gzsTotle,
      lineColor: 'l(180) 0:#6C8CFF 1:#9767FF',
      areaColor: 'l(90) 0:rgba(108, 140, 255, 1) 1:rgba(151, 103, 255, 0.1) 100%',
      data: weeksData?.gzsTotle || null
    },
    {
      name: "环球资讯广播",
      count: hqsTotle,
      lineColor: 'l(180) 0:#E323FF 1:#7517F8',
      areaColor: 'l(90) 0:rgba(226, 34, 254, 1) 0% 1:rgba(117, 23, 248, 0.0001) 100%',
      data: weeksData?.hqsTotle || null
    },
    {
      name: "大湾区之声",
      count: hwTotle,
      lineColor: 'l(180) 0:#E123FF 1:#FF5C5C',
      areaColor: 'l(90) 0:rgba(255, 92, 94, 1) 0% 1:rgba(225, 35, 254, 0.0001) 94.59%',
      data: weeksData?.hwTotle || null
    },
    {
      name: "台海之声",
      count: twzsTotle,
      lineColor: 'l(180) 0:#FFA63F 1:#FF5C5D',
      areaColor: 'l(90) 0:rgba(255, 164, 63, 1) 0% 1:rgba(255, 92, 93, 0.0001) 100%',
      data: weeksData?.twzsTotle || null
    }
  ]
  return (
    <div className={prefix}>
      {
        Array.isArray(dataList) ? dataList.map((item, i) => item?.count && <OutlineFlagshipPlatformItem key={item.name} {...item} pos={i} />) : ''
      }
    </div>
  )
}
export default OutlineFlagshipPlatformModal;
