import classNames from 'classnames';
import React from 'react';

import './TypographyList.less'

const prefix = 'typography-list'

const TypographyItem = ({ className, content, type, number, showShadow, index }) =>
  <div
    className={classNames(`${prefix}-item`, className, showShadow ? `${prefix}-shadow` : '', 'element-right-in')} style={{
      animationDirection: '0.1s',
      animationDelay: `${0.8 + index * 0.2}s`
    }}
  >
    {
      type === 'bulleted' ?
        <img alt='' className={`${prefix}-item-bulleted`} src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/bulleted-icon.png' /> :
        <span className={`${prefix}-item-number`}>{number}</span>
    }
    <span className={`${prefix}-item-content`}>{content}</span>
  </div>


const TypographyList = (props) => {
  const { className, type, content, showShadow } = props || {}
  return (
    <div className={prefix}>
      {Array.isArray(content) && content.map((item, i) => <TypographyItem key={i + 1} type={type} number={i + 1} content={item} className={className} showShadow={showShadow} index={i} />)}
    </div>
  )
}
export default TypographyList;