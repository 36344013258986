import React, { useEffect, useRef, useState } from 'react';
import { Chart } from '@antv/g2';

import './OutlineFlagshipPlatformItem.less';
import classNames from 'classnames';
import { setTimeoutWithRAF } from '../../../../../utils';

const prefix = 'outline-flagship-platform-item'
const OutlineFlagshipPlatformItem = ({ name, count, data, pos, lineColor, areaColor }) => {
  const chartRef = useRef()
  useEffect(() => {
    const chart = new Chart({
      container: chartRef.current,
      forceFit: true,
      autoFit: true,
      height: 100,
      appendPadding: 5,
    });

    chart.data(data);
    chart.scale(
      {},
      {
        nice: true,
      }
    );
    // 设置面积图出场动画
    chart.animate({
      appear: {
        animation: 'fade-in', // 动画名称
        easing: 'easeQuadIn', // 动画缓动效果
        delay: 100, // 动画延迟执行时间
        duration: 600 // 动画执行时间
      }
    });

    chart.axis('value', false);
    chart.axis('date', { line: null, tickLine: null, subTickLine: null });

    chart.area().position('date*value').color(areaColor).shape('smooth');
    chart.line().position('date*value').size(4).color(lineColor).shape('smooth')
    chart.tooltip({
      // follow: false,
      shared: false,
      position: 'top',
      customContent: (name, items) => {
        const container = document.createElement('div');
        container.className = 'g2-tooltip';
        let listItem = '';
        items.forEach((item) => {
          listItem += `<li class="g2-tooltip-list-item" data-index={index} style="margin-bottom:4px;display:flex;align-items: center;">
                <span>${item?.value}</span>
            </li>`;
        });
        container.innerHTML = listItem;
        return container;
      }
    });
    setTimeoutWithRAF(() => {
      chart.render();
    }, 2000)
  }, [])

  return (
    <div className={classNames(prefix, 'element-top-in')}>
      <div
        className='element-top-in'
        style={{
          animationDelay: '0.8s'
        }}
      >
        <div
          className={`${prefix}-title`}
        >
          {name}
        </div>
        <div className={classNames(`${prefix}-count`, `${prefix}-count-${pos + 1}`)}>
          {count}<span>条</span>
        </div>
      </div>
      <div
        className={`${prefix}-chart element-top-in`}
        ref={chartRef}
        style={{
          animationDelay: '1.5s'
        }}
      />
    </div>
  )
}
export default OutlineFlagshipPlatformItem;
