/**
 * 评论言论
 */
import React, { useEffect, useState } from 'react';
import ModalGroup from '@/components/ModalGroup'
import CommentCMGModal from './components/CommentCMGModal'
import CommentDetailModal from './components/CommentDetailModal'
import CommentOtherMediaModal from './components/CommentOtherMediaModal'

import './index.less'
const CommentTypeEnum = {
  CMG: 1,
  Other: 2
}

const getCommentTypeData = (list, type) => {
  if (!Array.isArray(list)) return []
  return list.filter(item => Number(item.type) === type)
}

const prefix = 'comment-modal'
const CommentModal = ({ onClose, path, data }) => {
  const [title, setTitle] = useState("总台评论言论")
  const [onShow, setOnShow] = useState(path || "comment-cmg")

  const { impor5 } = data || {}

  const items = [
    {
      id: "comment-cmg",
      title: "总台评论言论",
      element: <CommentCMGModal list={getCommentTypeData(impor5, CommentTypeEnum.CMG)} />,
      width: "",
      height: "",
    },
    // {
    //   id: "comment-detail",
    //   title: "评论言论",
    //   element: <CommentDetailModal />,
    //   width: "",
    //   height: "",
    //   isHidden: true
    // },
    {
      id: "comment-other-media",
      title: "其他媒体评论言论",
      element: <CommentOtherMediaModal list={getCommentTypeData(impor5, CommentTypeEnum.Other)} />,
      width: "",
      height: "",
    }
  ]

  const onChangeShow = (id) => {
    const item = items.find(item => item.id === id)
    setOnShow(id)
    item?.title && setTitle(item?.title)
  }

  return (
    <ModalGroup
      className={prefix}
      title={title}
      onShow={onShow}
      setOnShow={onChangeShow}
      items={items}
      onClose={() => onClose?.()}
    />
  )
}
export default CommentModal;