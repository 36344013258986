import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import './PageHeader.less'

const PageHeader = ({ date, dataRange, dateDesc, onChange, onChangeDate }) => {
  const currentDay = dayjs(date);
  const today = dayjs()
  const isToday = today.format('YYYY-MM-DD') === currentDay.format('YYYY-MM-DD')

  return (
    <div className='page-header'>
      <video autoPlay muted loop>
        <source src="https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/page-header-background-image.webm" type="video/mp4" />
          Your browser does not support HTML5 video.
      </video>
      <div className='issue'>总第{dataRange}期</div>
      <div className='title'>
        <img alt='' src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/page-title-image.png' />
      </div>
      <div className='extra'>
        <div className='date'>
          <img className='left' onClick={() => onChange?.(currentDay.subtract(1, 'day').format('YYYY-MM-DD'))} src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/date-change-right-icon.png' />
          <span onClick={onChangeDate}>{currentDay.format('YYYY年MM月DD日')}</span>
          <img className={classNames(isToday && 'disabled')} onClick={() => !isToday && onChange?.(currentDay.add(1, 'day').format('YYYY-MM-DD'))} src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/date-change-right-icon.png' />
        </div>
        <div className='date-range'>数据周期：{dateDesc}</div>
      </div>
    </div>
  )
}
export default PageHeader;
