/**
 * 其他媒体检测
 */
import React, { useEffect, useState } from 'react';
import ModalGroup from '@/components/ModalGroup'
import OtherMediaNewsTopicsModal from './components/OtherMediaNewsTopicsModal'
import OtherMediaHotBroadcastingModal from './components/OtherMediaHotBroadcastingModal'
import OtherMediaProgramPreviewModal from './components/OtherMediaProgramPreviewModal'
import OtherMediaNewsInternationalMediaModal from './components/OtherMediaNewsInternationalMediaModal'


const ProgramType = [
  {
    name: '综艺',
    icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/variety-icon.png",
  },
  {
    name: '电视剧',
    icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/tv-play-icon.png",
  },
  {
    name: '纪录片',
    icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/documentary-film-icon.png",
  },
  {
    name: '专题片',
    icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/documentary-film-icon.png",
  },
  {
    name: '特别节目',
    icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/documentary-film-icon.png",
  }
]


const formatOtherMediaData = (list) => {
  if (!Array.isArray(list)) return []
  let data = []
  ProgramType.map(({ name, icon }, i) => {
    const current = list?.[i] || []
    const newList = []
    Array.isArray(current) && current.map(({ title, key, imgarry }) => {
      if (title) {
        newList.push({
          key,
          source: `https://zbso.oss-cn-beijing.aliyuncs.com/LOCALLOGO/${imgarry?.[0] || ''}.png`,
          name: title,
        })
      }
    })

    if (newList.length > 0) {
      data.push({
        name,
        icon,
        list: newList
      })
    }
  })
  return data
}

const OtherMediaModal = ({ onClose, data }) => {
  const [title, setTitle] = useState("新闻专题")
  const [onShow, setOnShow] = useState("other-media-news-topics")
  const { impor7 = {} } = data || {}
  const {
    ztList1,
    ztList2,
    hot01,
    hot02,
    hot03,
    hot07,
    hot09,
    hot05,
    hot04,
    hot06,
    hot08,
    hot010,
    g7Cont1,
    g7Cont2,
    g7Cont3,
    g7Cont4,
    g7Cont5,
    g7Cont6,
    g7Cont7,
    g7Num
  } = impor7?.[0] || {}

  const items = [
    {
      id: "other-media-news-topics",
      title: "新闻专题",
      element: <OtherMediaNewsTopicsModal pepolesDaily={ztList1} xinhuaNews={ztList2} />,
      width: "",
      height: "",
    },
    {
      id: "other-media-hot-broadcasting",
      title: "正在热播",
      element: <OtherMediaHotBroadcastingModal list={formatOtherMediaData([hot01, hot02, hot03, hot07, hot09])} />,
      width: "",
      height: "",
    },
    {
      id: "other-media-program-preview",
      title: "新节目预告",
      element: <OtherMediaProgramPreviewModal list={formatOtherMediaData([hot05, hot04, hot06, hot08, hot010])} />,
      width: "",
      height: "",
    },
    {
      id: "other-media-news-internation-media",
      title: "国际其他媒体",
      element: <OtherMediaNewsInternationalMediaModal {...{
        g7Cont1,
        g7Cont2,
        g7Cont3,
        g7Cont4,
        g7Cont5,
        g7Cont6,
        g7Cont7,
        g7Num
      }} />,
      width: "",
      height: "",
    }
  ]

  const onChangeShow = (id) => {
    const item = items.find(item => item.id === id)
    setOnShow(id)
    item?.title && setTitle(item?.title)
  }

  return (
    <ModalGroup
      title={title}
      onShow={onShow}
      onClose={onClose}
      setOnShow={onChangeShow}
      items={items}
    />
  )
}
export default OtherMediaModal;