/**
 * 头条工程：总台头条
 */
import React from 'react';
import { drawNumber } from '@/utils';
import Card from '@/components/Card'
import IconTypeCount from '@/components/IconTypeCount';

import './index.less'
import { Popover } from 'antd';

const prefix = 'headline-project-cmg-modal'
const HeadlineProjectCMGModal = ({ ttotle, tzh, tcn, txyz, tmt01, tmt02, tmt03, info }) => {
  const languageData = [
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/cn-language-icon.png",
      name: "中文",
      count: drawNumber(tzh)
    },
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/en-language-icon.png",
      name: "英文",
      count: drawNumber(tcn)
    },
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/other-language-icon.png",
      name: "小语种",
      count: drawNumber(txyz)
    },
  ]

  const mediaData = [
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/new-media-icon.png",
      name: "新媒体",
      count: drawNumber(tmt01)
    },
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/tv-icon.png",
      name: "电视",
      count: drawNumber(tmt02)
    },
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/broadcast-icon.png",
      name: "广播",
      count: drawNumber(tmt03)
    },
  ]

  const renderContentItem = (item, i) => {
    const cont = item.cont || '';
    let contList = [];

    if (cont.indexOf('｜') > -1) {
      contList = cont.split('｜')
      console.log(contList, cont)
    } else if (cont.indexOf('丨') > -1) {
      contList = cont.split('丨')
    } else if (cont.indexOf('|') > -1) {
      contList = cont.split('|')
    }
  
    let content = '';
    let title = '';
    if (contList.length > 1) {
      title = contList[0]?.trim();
      content = contList.slice(1, contList.length).join(' | ');
    } else {
      content = cont
    }
    return <div
      key={item.key}
      className={`${prefix}-content-item element-right-in`}
      style={{
        animationDirection: '0.2s',
        animationDelay: '1s'
      }}
    >
      <div className={`${prefix}-content-item-title`}><span>{i + 1}</span>{title}</div>
      <div className={`${prefix}-content-item-content`}>{content}</div>
    </div>
  }

  const renderThemeItem = (info, i) => {
    return <div
      key={i}
      className={`${prefix}-theme-item element-right-in`}
      style={{
        animationDirection: '0.2s',
        animationDelay: '1s'
      }}
    >
      <div className={`${prefix}-theme-item-title`}><div>主题{i + 1}</div>{info?.title}</div>
      <div className={`${prefix}-theme-item-content`}>
        {
          Array.isArray(info?.cont || []) && info.cont.map((item, j) =>
            <div key={j}>
              <img src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/headline-project-doted.png' />
              <div>
                <p>
                  {item.cont}
                </p>
                {
                  (item?.zdnum || item?.zznum) && <div className={`${prefix}-theme-extra`}>
                    {
                      item.zznum && <div className={`${prefix}-theme-share`}>
                        <Popover placement='top' content='转载量' overlayClassName={`${prefix}-tooltip`}>
                          <img src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/headline-project-share.png' />
                          {item.zznum}
                        </Popover>
                      </div>
                    }
                    {
                      item?.zdnum && <div className={`${prefix}-theme-share`}>
                        <Popover placement='top' content='置顶次数' overlayClassName={`${prefix}-tooltip`}>
                          <img src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/headline-project-overhead.png' />
                          {item.zdnum}
                        </Popover>
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          )
        }
      </div>
    </div>
  }

  const renderContentGroup = (item, i) => {
    console.log(item)
    if (item.title) {
      return renderThemeItem(item, i)
    } else {
      return item?.cont?.map((cont, j) => renderContentItem(cont, j))
    }
  }
  return (
    <div className={prefix}>
      <div className={`${prefix}-data-view`}>
        <div className={`${prefix}-overview`}>
          <img
            alt=''
            src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/headline-overview-icon.png' className='element-top-in'
            style={{
              animationDirection: '0.1s',
              animationDelay: '0.1s'
            }}
          />
          <div
            className={`${prefix}-overview-content element-right-in`}
            style={{
              animationDirection: '0.4s',
              animationDelay: '0.5s'
            }}
          >
            <div className={`${prefix}-overview-count`}>{ttotle}<span>条</span></div>
            <div className={`${prefix}-overview-type`}>头条工程播发数</div>
          </div>
        </div>
        <Card>
          <div className={`${prefix}-data-group`}>
            {languageData.map((item, i) => <IconTypeCount
              key={i}
              {...item}
              animationDelay='0.9s'
              className='element-right-in'
            />)}
          </div>
        </Card>
        <Card>
          <div className={`${prefix}-data-group`}>
            {mediaData.map((item, i) => <IconTypeCount
              key={i}
              {...item}
              animationDelay='1s'
              className='element-right-in'
            />)}
          </div>
        </Card>
      </div>

      <Card className={`${prefix}-content`}>
        {
          Array.isArray(info) && info.map((item, i) => renderContentGroup(item, i))
        }
        {/**
         * TODO Hover效果
         */}
      </Card>
    </div>
  )
}
export default HeadlineProjectCMGModal;