/**
 * 评论言论：总台
 */
import React from 'react';
import ModalCard from '@/components/ModalCard';
import Card from '@/components/Card';

import './index.less'

const imgDomin = "https://cpec-impor.oss-cn-zhangjiakou.aliyuncs.com"
// const dataList = [
//   {
//     source: "玉渊潭天",
//     extra: "三年：三问三答"
//   },
//   {
//     source: "国际锐评",
//     extra: "中国抗疫的变与不变"
//   },
//   {
//     source: "主播说联播",
//     extra: "这份纲要，锚定咱们的美好生活"
//   },
//   {
//     source: "央视新闻热评",
//     extra: "我们终将走出疫情"
//   },
//   {
//     source: "CGTN评论",
//     extra: "大自然的高回报 漫话天下 | 一个也不该"
//   },
//   {
//     source: "央广网评",
//     extra: "央广时评 | 疫情防控新阶段 重点人群要重点防护"
//   },
// ]

const formatData = (list) => {
  if (!Array.isArray(list)) return []
  const data = []
  list.map(item => {
    data.push({
      icon: imgDomin + item.imgurl,
      source: item.pdname,
      extra: item.title
    })
    return item
  })

  return data
}

const renderTitle = ({ title, icon, extra }) => <div className={`${prefix}-card-title`}>
  <div className={`${prefix}-card-img`}>
    <img alt='' src={icon} />
  </div>
  <div>
    <div className={`${prefix}-card-header`}>{title}</div>
    <div className={`${prefix}-card-extra`} dangerouslySetInnerHTML={{ __html: extra }} />
  </div>

</div>

const prefix = 'comment-other-media-modal'
const CommentOtherMediaModal = ({ list }) => {
  const dataList = formatData(list)
  return (
    <div className={prefix}>
      <div className={`${prefix}-container`}>
        {
          Array.isArray(dataList) && dataList.map((item, i) => <div
            key={i}
            className='element-right-in'
            style={{
              animationDirection: '0.1s',
              animationDelay: `${0.1 + i * 0.2}s`
            }}
          >
            <Card
              key={item.source}
              className={`${prefix}-card element-right-in`}
              title={
                renderTitle({
                  title: item.source,
                  extra: item.extra?.replace?.(/\n/g, '<br/>'),
                  icon: item.icon,
                })}
            />
          </div>)
        }
      </div>
    </div>
  )
}
export default CommentOtherMediaModal;