import React, { useEffect, useState } from 'react';
import { setTimeoutWithRAF } from '../../../../../utils';

import './NewsItem.less'

const prefix = 'news-item'
const NewsItem = ({ continent, content, num, onSelect, isShow, bottom, left }) => {
  const [contentShow, changeContentShow] = useState(false)
  useEffect(() => {
    setTimeoutWithRAF(() => {
      changeContentShow(true)
    }, 1000)

  }, [isShow])
  const textLine = Math.ceil(content.length / 20)
  return (
    <div className={prefix} style={{ left, bottom }}>
      {
        isShow ?
          <div className={`${prefix}-news`}>
            <div className={`${prefix}-news-continent`}>
              {continent} <span>{num}</span>
              <img onClick={() => onSelect('')} src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/other-media-close.png' />
            </div>
            <div className={`${prefix}-news-content`} style={{ height: `${0.3 * (textLine <= 3 ? textLine : 3)}rem` }}>
              {contentShow && content}
            </div>
          </div> : <div className={`${prefix}-continent`} onClick={() => onSelect(continent)}>{continent}<span>{num}</span></div>
      }
      <img className={`${prefix}-arrow`} src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/word-map-arrow.png' />
      {
        !isShow ?
          <img className={`${prefix}-animate`} src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/word-map-animate.webp' /> : null
      }
    </div>
  )
}
export default NewsItem;