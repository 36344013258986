/**
 * 头条工程：总台头条
 */
import React from 'react';
import Card from '@/components/Card'
import TypographyList from '@/components/TypographyList'

import './index.less'

const OtherMediaType = {
  PepolesDaily: "人民日报",
  XinhuaNews: "新华社"
}

const formatData = (data, type) => {
  if (!Array.isArray(data)) return []
  let list = []
  data.map(item => {
    if (item.title === type) {
      Array.isArray(item.cont) && item.cont.map(i => {
        i?.cont && list.push(i.cont)
      })
    }
  })
  return list
}

const prefix = 'headline-project-other-media-modal'
const HeadlineProjectOtherMediaModal = ({ info }) => {
  const dataList = [
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/pepoles-daily-icon.png",
      source: OtherMediaType.PepolesDaily,
      content: formatData(info, OtherMediaType.PepolesDaily)
    },
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/xinhua-news-icon.png",
      source: OtherMediaType.XinhuaNews,
      content: formatData(info, OtherMediaType.XinhuaNews)
    }
  ]
  return (
    <div className={prefix}>
      {/** TODO */}
      {
        Array.isArray(dataList) && dataList.map(item => <Card
          className={`${prefix}-card`}
          key={item.source}
          title={<div
            className={`${prefix}-card-title element-right-in`}
            style={{
              animationDirection: '0.1s',
              animationDelay: '0.1s'
            }}
          >
            <img alt='' src={item.icon} />
            {item.source}
          </div>}
        >
          <div className={`${prefix}-card-content`}>
            <TypographyList key={item.source} className={`${prefix}-typography`} showShadow type="number" content={item.content} />
          </div>
        </Card>)
      }
    </div>
  )
}
export default HeadlineProjectOtherMediaModal;