/**
 * 评论言论
 */
import React, { useEffect, useState } from 'react';
import ModalGroup from '@/components/ModalGroup'
import EffectCMGModal from './components/EffectCMGModal'
import EffectCCTVModal from './components/EffectCCTVModal'
import EffectYangShiPinModal from './components/EffectYangShiPinModal'
import EffectCCTVPlusModal from './components/EffectCCTVPlusModal'
import EffectCGTNModal from './components/EffectCGTNModal'
import dayjs from 'dayjs';
import { getDateFromStr } from '../../utils/date';

const EffectModal = ({ onClose, data, weeks }) => {
  const [title, setTitle] = useState("总台央视份额")
  const [onShow, setOnShow] = useState("effect-cctv")

  const { impor01, dateDesc, dateStr } = data || {}

  let currentDayStr = '';
  if (impor01?.dateStr && (JSON.stringify(impor01?.dateStr) !== '{}')) {
    const dateDescObj = getDateFromStr(impor01?.dateStr, dayjs(dateStr).format("YYYY"));
    currentDayStr = dayjs(`${dateDescObj.year}-${dateDescObj.month}-${dateDescObj.day}`).format("YYYY-MM-DD");
  } else {
    const dateDescObj = getDateFromStr(dateDesc, dayjs(dateStr).format("YYYY"));
    currentDayStr = dayjs(`${dateDescObj.year}-${dateDescObj.month}-${dateDescObj.day}`).subtract(1, 'd').format("YYYY-MM-DD");
  }

  const { xmtTotle, xmtWx, xmtWb, xmtKhd, xmtQt, xmtDes, ftotle, tpeo, tfwl, tplay, ynDes, ctotle, rz, rp, rn, cdes, gtotle, gpeo, gcont, gtv, gply, gdes } = impor01;

  const items = [
    {
      id: "effect-cctv",
      title: "总台央视份额",
      element: <EffectCCTVModal {...impor01} dateStr={currentDayStr} weeks={weeks} />,
      width: "",
      height: "",
    },
    {
      id: "effect-cmg",
      title: "央视新闻",
      element: <EffectCMGModal {...impor01} dateStr={currentDayStr} />,
      isDetail: !(xmtTotle || xmtWx || xmtWb || xmtKhd || xmtQt || xmtDes),
      width: "",
      height: "",
    },
    {
      id: "effect-yangshipin",
      title: "央视频",
      element: <EffectYangShiPinModal {...impor01} dateStr={currentDayStr} />,
      isDetail: !(ftotle || tpeo || tfwl || tplay || ynDes),
      width: "",
      height: "",
    },
    {
      id: "effect-cgtn",
      title: "CGTN",
      element: <EffectCGTNModal {...impor01} dateStr={currentDayStr} />,
      isDetail: !(ctotle || rz || rp || rn || cdes),
      width: "",
      height: "",
    },
    {
      id: "effect-cctv-plus",
      title: "国际视频通讯社",
      element: <EffectCCTVPlusModal {...impor01} dateStr={currentDayStr} />,
      isDetail: !(gtotle || gpeo || gcont || gtv || gply || gdes),
      width: "",
      height: "",
    },
  ]

  const onChangeShow = (id) => {
    const item = items.find(item => item.id === id)
    setOnShow(id)
    item?.title && setTitle(item?.title)
  }

  return (
    <ModalGroup
      title={title}
      onShow={onShow}
      onClose={onClose}
      setOnShow={onChangeShow}
      items={items}
    />
  )
}
export default EffectModal;