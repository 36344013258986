/**
 * 评论言论
 */
import React from 'react';
import { Modal as AntdModal } from 'antd';
import DatePicker from '@/components/DatePicker'

import './index.less'

const prefix = 'date-picker-modal';
const DatePickerModal = ({ message, startDay, endDay, value, onChange, width = '1032px', top = "208px" }) => {
  const handleClose = (value) => {
    onChange(value)
  }
  return (
    <AntdModal
      className={`${prefix}-modal`}
      maskStyle={{
        background: "linear-gradient(180deg, rgba(12,16,27,0.75) 0%, rgba(10,15,28,0.85) 100%)"
      }}
      open={true}
      mask={false}
      transitionName=''
      footer={null}
      closable={false}
      style={{
        top,
        width
      }}
    >
      <DatePicker message={message} value={value} onChange={handleClose} startDay={startDay} endDay={endDay} />
    </AntdModal>
  )
}
export default DatePickerModal;