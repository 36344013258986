/**
 * 总台每日宣传要情:当日重点节目
 */
import React, { useEffect, useState } from 'react';
import Carousel from '@/components/Carousel';
import OutlineKeyProgramsDataView from './components/OutlineKeyProgramsDataView';

import './index.less';
import classNames from 'classnames';
import { setTimeoutWithRAF } from '../../../../utils';

const formatData = (list) => {
  if (!Array.isArray(list)) return []

  let data = []
  list.map(({ mtcont, cont }) => {
    const current = mtcont?.[0] ?? {};
    const { pdimg, pdname } = current;
    if (pdimg && pdname) {
      data.push({
        icon: "https://cpec-impor.oss-cn-zhangjiakou.aliyuncs.com" + current.pdimg,
        title: current.pdname,
        content: cont,
      })
    }
  })
  return data;
}

const prefix = 'outline-key-programs-modal'
const OutlineKeyProgramsModal = ({ list }) => {
  const [active, setActive] = useState(2)
  const [isDelayed, setIsDelayed] = useState(true)
  const dataList = formatData(list)
  const elements = dataList.map((item, i) =>
    <OutlineKeyProgramsDataView
      isActive={active === i}
      {...item}
      key={item.icon}
    />)
  const current = dataList?.[active] || {}

  useEffect(() => {
    setTimeoutWithRAF(() => {
      setIsDelayed(false)
    }, 500)
  }, [current?.content || ''])
  return (
    <div className={prefix}>
      <div className={`${prefix}-container`}>
        <Carousel
          elements={elements}
          elementWidth="1.48"
          elementHeight="5.62"
          pageNum={5}
          setActive={(current) => {
            if(current === active) return;
            setActive(current);
            setIsDelayed(true)
          }}
          onPrev={() => {
            if (active > 0) {
              setActive(active - 1)
              setIsDelayed(true)
            }
          }}
          onNext={() => {
            if (active < dataList.length - 1) {
              setActive(active + 1)
              setIsDelayed(true)
            }
          }}
        />
      </div>
      <div className={classNames(`${prefix}-text`, !isDelayed ? `${prefix}-text-animation` : '')}>{current?.content || ''}</div>
    </div>
  )
}
export default OutlineKeyProgramsModal;