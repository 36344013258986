/**
 * 总台每日宣传要情
 */
import React, { useEffect, useState } from 'react';
import ModalGroup from '@/components/ModalGroup'
import OutlineFlagshipPlatformModal from './components/OutlineFlagshipPlatformModal'
import OutlineKeyProgramsModal from './components/OutlineKeyProgramsModal'
import OutlineDailyPointsModal from './components/OutlineDailyPointsModal'

import './index.less'

const prefix = 'outline-daily-modal'
const OutlineDailyModal = ({ path, onClose, data, weeks }) => {
  let currentItem = ""
  const { impormain, impor2 } = data || {}
  const { brightSpot, todes } = impormain || {}

  const getTitle = () => {
    return brightSpot?.[0]?.customTtile || "每日亮点"
  }

  const isOutlineDailyPoints = Boolean((Array.isArray(brightSpot) && brightSpot.length > 0) || todes)

  const [title, setTitle] = useState("")

  const items = [
    {
      id: "outline-daily-points",
      title: "每日亮点",
      element: <OutlineDailyPointsModal {...impormain} setTitle={setTitle} />,
      isDetail: !isOutlineDailyPoints,
      width: "",
      height: "",
    },
    {
      id: "outline-flagship-platform",
      title: "旗舰频道播发数",
      element: <OutlineFlagshipPlatformModal {...impormain} weeks={weeks} />,
      width: "",
      height: "",
    },
    {
      id: "outline-key-programs",
      title: "当日重点节目",
      element: <OutlineKeyProgramsModal list={impor2} />,
      width: "",
      height: "",
    },
  ]
  useEffect(() => {
    currentItem = items.find(item => item.id === path) || {
      id: "outline-flagship-platform",
      title: "旗舰频道播发数"
    }
    setTitle(path === "outline-daily-points" ? getTitle(impormain) : currentItem.title)
  }, [path])

  const [onShow, setOnShow] = useState(path)

  const onChangeShow = (id) => {
    const item = items.find(item => item.id === id)
    item?.title && setTitle(id === "outline-daily-points" ? getTitle(impormain) : item.title)
    setOnShow(id)
  }

  return (
    <ModalGroup
      className={`${prefix}`}
      title={title}
      onShow={onShow}
      setOnShow={onChangeShow}
      onClose={() => onClose?.()}
      items={items}
      width='1400px'
      top="152px"
    />
  )
}
export default OutlineDailyModal;