import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import './OutlineKeyProgramsDataView.less';
import { setTimeoutWithRAF } from '../../../../../utils';

const prefix = 'outline-key-programs-data-view';
const OutlineKeyProgramsDataView = ({ icon, isActive }) => {
  const [isDelayed, setIsDelayed ] = useState(false);
  useEffect(() => {
    setTimeoutWithRAF(() => {
      setIsDelayed(true)
    }, 100)
  },[icon])
  return (
    <div className={
      classNames(
        prefix,
        isActive ? `${prefix}-active` : '',
        !isDelayed ? `element-top-in`: ''
      )}>
      <div className={`${prefix}-img`} style={{ backgroundImage: `url(${icon})` }}>
        <img alt='' src={'https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/OutlineKeyProgramsDataView-bg.png'} />
      </div>
    </div>
  )
}
export default OutlineKeyProgramsDataView;