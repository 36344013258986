/**
 * 总台每日宣传要情-每日亮点
 */

import React from 'react';
import PageCarousel from '@/components/PageCarousel';
import OutlineDataView from './components/OutlineDailyDataView';

import './index.less'

const prefix = 'outline-daily-points-modal'
const getProportionWidth = (list, current) => {
  return (list.length % 2) === 1 && (current === 0) ? '100%' : '50%'
}
const OutlineDailyPointsModal = ({ brightSpot, todes, setTitle }) => {
  if (Array.isArray(brightSpot) && brightSpot.length) {
    const elements = (brightSpot || []).map(item => {
      const proportion = item?.template?.proportion || []
      const length = Array.isArray(proportion) && proportion.length ? proportion.length : 0;

      return <div key={item.title} className={`${prefix}-item`}>
        <div className={`${prefix}-overview`}>
          <div className={`${prefix}-image`}>
            <img alt='' src={`https://cpec-impor.oss-cn-zhangjiakou.aliyuncs.com${item.image}`} />
            {
              item.title && <div>{item.title}</div>
            }
          </div>
          {
            length > 0 ?
              <div className={`${prefix}-data-view`}>
                <div className={`${prefix}-data-view-warp`}>
                  {
                    Array.isArray(proportion) && proportion.map((p, i) => p.name ? <OutlineDataView key={i} {...p} width={getProportionWidth(proportion, i)} length={proportion.length} pos={i} /> : null)
                  }
                </div>
              </div> : null
          }
        </div>
        <div className={`${prefix}-content`} dangerouslySetInnerHTML={{ __html: item?.template?.remark?.replace?.(/\n/g, '<br/>') }} />
      </div>
    })

    const onPageChange = (current) => {
      const title = brightSpot?.[current]?.customTtile || '每日亮点'
      setTitle(title)
    }

    return (
      <div className={prefix}>
        <div className={`${prefix}-list`}>
          <PageCarousel onChange={onPageChange} elements={elements} elementWidth="11.73" elementHeight="4.91" pageNum={1} />
        </div>
      </div>
    )
  }

  if (todes) {
    return <div className={prefix}>
      <div className={`${prefix}-todes-list`}>
        <div className={`${prefix}-todes-content`} dangerouslySetInnerHTML={{ __html: todes?.replace?.(/\n/g, '<br/>') }} />
      </div>
    </div>
  }
}
export default OutlineDailyPointsModal;
