
/**
 * 传播效果数据：正在热播
 */
import React, { useState } from 'react';
import Tabs from '@/components/Tabs';
import Card from '@/components/Card';

import './index.less'
import classNames from 'classnames';

const prefix = 'effect-hot-broadcasting-modal'

const Carousel = ({ elements, onChange, setActive, onPrev, onNext }) => {
  const [current, setCurrent] = useState(0);

  const handlePrev = () => {
    if (current > -1) {
      setCurrent((current) => current - 1);
      onChange?.(current - 1);
      onPrev?.();
    }
  };

  const handleNext = () => {
    if (current < elements.length - 2) {
      setCurrent((current) => current + 1);
      onChange?.(current + 1);
      onNext?.();
    };
  };

  const sliderStyle = {
    transform: current > 0 ? `translateX(-${current * 3.0218}rem)` : `translateX(${(-current) * 3.0218}rem)`,
    transition: 'transform 0.5s ease-in-out',
    width: `${elements.length * 1.7558}rem`,
  };

  return (
    <div className="carousel">
      <div className={`${prefix}-conatiner`}>
        <div 
          className="slider" style={sliderStyle}
        >
          {elements.map((element, index) => (
            <div
              key={index}
              className="slide element-top-in"
              style={{
                animationDelay: '1s'
              }}
              onClick={() => {
                setCurrent(index - 1);
                setActive?.(index);
              }}
            >
              {element}
            </div>
          ))}
        </div>
      </div>
      <img src="https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/other-media-arrow.png" className="control prev" onClick={() => handlePrev(current - 1)} />
      <img src="https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/other-media-arrow.png" className="control next" onClick={() => handleNext(current + 1)} />
    </div>
  );
};

const EffectHotBroadcastingModal = ({ list }) => {
  const [active, setActive] = useState()
  const [activeItem, setActiveItem] = useState(1)
  useState(() => {
    setActive(list?.[0]?.name || "")
  }, [list?.length])
  return (
    <div className={prefix}>
      <Tabs
        className={`${prefix}-tab`}
        activeKey={active}
        onChange={setActive}
        keyInItem="name"
        childrenInItem="list"
        formatLabel={item => <>
          <img src={item.icon} />
          {item.name}
        </>}
        formatItem={items => {
          const list = []
          Array.isArray(items) && items.map((item, i) => {
            list.push(<div key={item.source} className={
              classNames(
                `${prefix}-item`,
                activeItem === i ?
                  `${prefix}-item-active` : '')
            }>
              <div>
                <img src={item.source} />
                <div>{item.name}</div>
              </div>
            </div>)
          })
          return (
            <div>
              <Carousel
                elements={list}
                elementWidth="1.48"
                elementHeight="5.62"
                pageNum={5}
                hideAction
                setActive={(current) => {
                  setActiveItem(current);
                  // setIsDelayed(true)
                }}
                onNext={() => {
                  setActiveItem(activeItem + 1);
                  // setIsDelayed(true)
                }}
                onPrev={() => {
                  setActiveItem(activeItem - 1);
                  // setIsDelayed(true)
                }}
              />
            </div>
          );
        }}
        items={list}
      />
    </div>
  )
}
export default EffectHotBroadcastingModal;