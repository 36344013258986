import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { drawNumber, setTimeoutWithRAF } from '../utils';
import ScrollingNumber from './ScrollingNumber';

import './DataOverview.less'

const prefix = 'data-overview'

const DataOverview = ({
  fzh,
  fen,
  fxyz,
  tnSb01,
  tnSb02,
  numbers,
  brightSpot,
  todes,
  setOpenModal,
  currentModal,
  data,
  message,
  preview
}) => {
  const [isDelayed, setIsDelayed] = useState(false);
  const [isAnimteDone, setIsAnimteDone] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const isShowModal = currentModal?.modal === 'data-overview'
  useEffect(() => {
    setTimeoutWithRAF(() => {
      setIsDelayed(true)
    }, 1300)
    setTimeoutWithRAF(() => {
      setIsAnimteDone(true)
    }, 2000)
  }, [])

  useEffect(() => {
    if (!preview) {
      setTimeoutWithRAF(() => {
        setIsClosing(false)
      }, 0)
    }
  }, [preview])

  const openModal = ({ modal, path, value }) => {
    if (data?.[path]?.[value] === '') {
      message.open({
        className: 'app-message',
        icon: <></>,
        content: '当日暂无数据',
      });
      return false
    }
    if (!isShowModal) return setOpenModal({ modal, path });
    setIsClosing(true)
    setOpenModal({ modal, path, preview: 'data-overview' })
  }

  const openCurrentModal = () => {
    if (isShowModal) return;
    setOpenModal({ modal: 'data-overview' })
  }

  return (
    <div className={
      classNames(
        `${prefix}`,
        !isAnimteDone ? `${prefix}-fade-in` : `${prefix}-nomal`,
        isShowModal || preview ? `${prefix}-modal` : `${prefix}-common`,
        isClosing ? `${prefix}-closing` : ``
      )}
      onClick={openCurrentModal}
    >
      {
        isDelayed ? <>
          <div className={`${prefix}-numbers`}>
            {numbers.map((item, i) => <div key={i} className={`${prefix}-number`}>
              <ScrollingNumber currentNumber={Number(item)} />
            </div>)}
          </div>
          <div className={`${prefix}-title`}>总台本部全媒体发稿（条）</div>
          <div className={`${prefix}-line`}>
            <div className='before'></div>
            <div className='after'></div>
          </div>
          <div className={`${prefix}-data`}>
            {
              fzh && <div className={`${prefix}-data-item`}>
                <div className='count'>{drawNumber(String(fzh))}</div>
                <div className='type'>中文(条)</div>
              </div>
            }
            {
              fen && <div className={`${prefix}-data-item`}>
                <div className='count'>{drawNumber(fen)}</div>
                <div className='type'>英文(条)</div>
              </div>
            }
            {
              fxyz && <div className={`${prefix}-data-item`}>
                <div className='count'>{drawNumber(fxyz)}</div>
                <div className='type'>其他42个小语种(条)</div>
              </div>
            }
            {
              tnSb01 && tnSb01 !== '0' && <div className={`${prefix}-data-item`}>
                <div className='count'>{drawNumber(tnSb01)}</div>
                <div className='type'>首播(条)</div>
              </div>
            }
            {
              tnSb02 && tnSb02 !== '0' && <div className={`${prefix}-data-item`}>
                <div className='count'>{drawNumber(tnSb02)}</div>
                <div className='type'>重播(条)</div>
              </div>
            }
          </div>
          <div className={`${prefix}-entrances`}>
            <div
              className={`${prefix}-entrance`}
              onClick={(event) => {
                event.stopPropagation();

                const isOutlineDailyPoints = Boolean((Array.isArray(brightSpot) && brightSpot.length > 0) || todes)

                if(!isOutlineDailyPoints) {
                  return message.open({
                    className: 'app-message',
                    icon: <></>,
                    content: '当日暂无数据',
                  });
                }
                openModal({
                  modal: "OutlineDailyModal",
                  path: "outline-daily-points"
                })
              }}
            >
              每日亮点
            </div>
            <div
              className={`${prefix}-entrance`}
              onClick={(event) => {
                event.stopPropagation();
                openModal({
                  modal: "OutlineDailyModal",
                  path: "outline-flagship-platform"
                })
              }}
            >
              旗舰频道
            </div>
            <div
              className={`${prefix}-entrance`}
              onClick={(event) => {
                event.stopPropagation();
                openModal({
                  modal: "OutlineDailyModal",
                  path: "outline-key-programs"
                })
              }}
              style={{ width: '1.78rem' }}
            >
              当日重点节目
            </div>
          </div>
        </> : null}
    </div>
  )
}

export default DataOverview;