import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PageHeader from '@/components/PageHeader';
import DataOverview from '@/components/DataOverview'
import EffectModal from '@/modals/EffectModal';
import CommentModal from '@/modals/CommentModal';
import HeadlineProjectModal from '@/modals/HeadlineProjectModal';
import InternationalCommunicationModal from '@/modals/InternationalCommunicationModal';
import KeyReportsModal from '@/modals/KeyReportsModal';
import OtherMediaModal from '@/modals/OtherMediaModal';
import OutlineDailyModal from '@/modals/OutlineDailyModal';
import DatePickerModal from '@/modals/DatePickerModal';
import { formatStrToObject, setTimeoutWithRAF } from './utils';
import ImageHover from './components/ImageHover';
import useDailyData from './hooks/useDailyData';
import { message } from 'antd';

import '@/utils/rem.js';

import './App.less';

const prefix = 'app'

// 用一个hooks来管理所有的动画

const typeBtnList = [
  {
    name: "国际传播",
    img: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/index-guojizhanbo.png",
    modal: "InternationalCommunicationModal",
    path: "international-communication-overview",
    zIndex: 3,
    path: "impormain",
    value: "gtotle"
  },
  {
    name: "传播效果数据",
    img: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/index-chuanboxiaoguoshuju.png",
    modal: "EffectModal",
    path: "effect-cmg",
    zIndex: 2
  },
  {
    name: "其他媒体监测",
    img: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/index-qitameitijiance.png",
    modal: "OtherMediaModal",
    path: "other-media-news-topics",
    zIndex: 2
  },
  {
    name: "头条工程",
    img: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/index-toutiaogongcheng.png",
    modal: "HeadlineProjectModal",
    path: "headline-project-cmg",
    className: `${prefix}-headline-project`,
    zIndex: 2
  },
  {
    name: "重点报道",
    img: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/index-cmg.png",
    modal: "KeyReportsModal",
    path: "key-report-key-themes-list",
    className: `${prefix}-key-report`,
    zIndex: 2
  },
  {
    name: "评论言论",
    img: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/index-pinlunyanlun.png",
    modal: "CommentModal",
    path: "comment-cmg",
    zIndex: 2
  }
]

const startingDay = dayjs('2021-01-01')

function App() {
  const now = dayjs(); // 获取当前时间
  const startOfDay = now.startOf('day'); // 获取当天开始时间
  const targetTime = startOfDay.add(8, 'hour').add(30, 'minute');
  const [messageApi, contextHolder] = message.useMessage();

  const [openModal, setOpenModal] = useState({
    modal: "",
    path: "",
    preview: ""
  });

  const [showMask, setShowMask] = useState(false);

  const [number, setNumber] = useState([0, 0, 0, 0, 0]);

  const [currentDay, setCurrentDay] = useState(now.isBefore(targetTime) ? now.subtract(1, 'day').format('YYYY-MM-DD') : now.format('YYYY-MM-DD'))
  const [isDelay, setIsDelay] = useState(false);

  const dailyDataForWeek = useDailyData(currentDay)
  const currentData = dailyDataForWeek?.find(item => item.dateStr === currentDay)
  const data = formatStrToObject(currentData)

  const { impormain } = data || {}

  useEffect(() => {
    const ftotle = impormain?.ftotle
    const numbers = Number(ftotle).toString().split('')
    let numberList = new Array(5).fill(0)
    Array.isArray(numberList) && numberList.map((item, i) => numberList[i] = numbers[i] || numberList[i])
    setNumber(numberList)
  }, [impormain?.ftotle])

  useEffect(() => {
    setTimeoutWithRAF(() => {
      setIsDelay(true);
    }, 1200)
  }, []);

  const onChangeCurrentDay = async (currentDay) => {
    // if(startingDay.isAfter(currentDay) || dayjs().isBefore(currentDay)) {
    //   return messageApi.open({
    //     className: 'app-message',
    //     icon: <></>,
    //     content: '请选择2021年01月01日到今天之间的日期',
    //   });
    // }
    onCloseModal()
    setCurrentDay(currentDay)
  }

  const onCloseModal = (closeMask = true) => {
    const { preview } = openModal;
    if(preview) {
      return setOpenModal({ modal: preview });
    }
    setOpenModal({ modal: "", path: "" });
    closeMask && setShowMask(false);
  }

  const onClickForOpenModal = ({ modal, path, value }) => {
    if (data?.[path]?.[value] === '') {
      messageApi.open({
        className: 'app-message',
        icon: <></>,
        content: '当日暂无数据',
      });
      return false
    }
    onCloseModal();
    handleOpenModal({ modal, path });
  }

  const handleOpenModal = (info) => {
    setOpenModal(info);
    setShowMask(true);
  }

  return (
    <div className={`${`${prefix}-warp`}`}>
      {
        isDelay ?
          <div className={prefix}>
            <video autoPlay muted loop>
              <source src="https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/index-bg-animation.webm" type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
            {contextHolder}
            <PageHeader date={currentDay} dataRange={(data?.dataRange || 0) - 1} onChange={onChangeCurrentDay} dateDesc={data?.dateDesc} onChangeDate={() => handleOpenModal({ modal: "DatePickerModal" })} />
            <div className={`${prefix}-container`}>
              <DataOverview currentModal={openModal} data={data} numbers={number} {...impormain} setOpenModal={handleOpenModal} preview={openModal?.preview} />
              <img alt='' className={`${prefix}-element`} src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/index-lampstand-animation.webp' />
              {
                Array.isArray(typeBtnList) && typeBtnList.map((item, i) => <div
                  key={item.name}
                  className={`${prefix}-type-btn ${prefix}-type-btn-${i + 1} ${item?.className ?? ""}`}
                  style={{
                    zIndex: item.zIndex
                  }}
                  onClick={() => onClickForOpenModal(item)}
                >
                  <ImageHover className={`${prefix}-type-btn-hover-warp`}>
                    <div className={`${prefix}-type-btn-header`}>
                      {item.name}
                    </div>
                    <div className={`${prefix}-type-btn-container`}>
                      <img src={item.img} />
                    </div>
                  </ImageHover>
                  <img src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/index-type-btn.webp' />
                </div>)
              }
            </div>
            {
              openModal?.modal === "DatePickerModal" &&
              <DatePickerModal
                value={currentDay}
                message={messageApi}
                startDay={startingDay}
                endDay={now}
                onClose={onCloseModal}
                onChange={onChangeCurrentDay}
              />
            }
            {
              openModal?.modal === "EffectModal" &&
              <EffectModal
                onChange={onClickForOpenModal}
                onClose={onCloseModal}
                path={openModal.path}
                data={data}
                weeks={dailyDataForWeek}
              />
            }
            {
              openModal?.modal === "CommentModal" &&
              <CommentModal
                onChange={onClickForOpenModal}
                onClose={onCloseModal}
                path={openModal.path}
                data={data}
              />
            }
            {
              openModal?.modal === "HeadlineProjectModal" &&
              <HeadlineProjectModal
                onChange={onClickForOpenModal}
                onClose={onCloseModal}
                path={openModal.path}
                data={data}
              />
            }
            {
              openModal?.modal === "InternationalCommunicationModal" &&
              <InternationalCommunicationModal
                onChange={onClickForOpenModal}
                onClose={onCloseModal}
                path={openModal.path}
                data={data}
              />
            }
            {
              openModal?.modal === "KeyReportsModal" &&
              <KeyReportsModal
                onChange={onClickForOpenModal}
                onClose={onCloseModal}
                path={openModal.path}
                data={data}
              />
            }
            {
              openModal?.modal === "OtherMediaModal" &&
              <OtherMediaModal
                onChange={onClickForOpenModal}
                onClose={onCloseModal}
                path={openModal.path}
                data={data}
              />
            }
            {
              openModal?.modal === "OutlineDailyModal" &&
              <OutlineDailyModal
                onChange={onClickForOpenModal}
                onClose={onCloseModal}
                path={openModal.path}
                data={data}
                weeks={dailyDataForWeek}
              />
            }
            {
              showMask ? <>
                <div className="ant-modal-mask"></div>
                <img onClick={onCloseModal} className="modal-close" src="https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/close-icon.png" />
              </> : null
            }
          </div>
          : null
      }
    </div>
  );
}

export default App;
