/**
 * 重点报道：重点主题
 */
import React from 'react';
import Card from '@/components/Card';

import './index.less'

const prefix = 'key-reports-other-theme-modal'
const imgDomin = "https://cpec-impor.oss-cn-zhangjiakou.aliyuncs.com/"

const formatData = (list) => {
  if (!Array.isArray(list)) return []
  const data = []
  list.map(item => {
    data.push({
      icon: imgDomin + item.pdimg,
      source: item.pdname,
      extra: item.pdtitle
    })
    return item
  })

  return data
}

const renderTitle = ({ title, icon, extra }) => <div className={`${prefix}-card-title`}>
  <div className={`${prefix}-card-img`}>
    <img alt='' src={icon} />
  </div>
  <div className={`${prefix}-card-warp`}>
    <div className={`${prefix}-card-header`}>{title}</div>
  </div>
</div>

const KeyReportsOtherThemesModal = ({ list }) => {
  const dataList = formatData(list)
  return (
    <div className={prefix}>
      <div className={`${prefix}-container`}>
        {
          Array.isArray(dataList) && dataList.map(item =>
            <Card
              key={item.source}
              className={`${prefix}-card element-right-in`}
              title={
                renderTitle({
                  ...item,
                  title: item.source
                })}
            >
              {item.extra}
            </Card>)
        }
      </div>
    </div>
  )
}
export default KeyReportsOtherThemesModal;


{/* <Card className={`${prefix}-card`} title={<div className={`${prefix}-card-title`}>
<img alt='' src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/cctv6.png' />
新闻联播</div>}>中国经济将持续发展为世界经济带来更多活力，前11月我国国民经济数据总体运行在合理区间，黑龙江省粮食产量连续13年位居全国首新闻中心、财经中心、农业农村中心、港澳台中心、CGTN、华…</Card> */}