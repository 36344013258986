import React from 'react';
import Card from '@/components/Card'
import TypographyList from '@/components/TypographyList'

import './index.less'

const formatData = (str) => {
  const list = (str || "").split("\n")
  if (list.length === 0) return []
  return list.splice(list.length === 1 ? 0 : 1)
}

const prefix = 'international-communication-theme-modal'
const InternationalCommunicationOverviewModal = ({ gjbfDes }) => {
  return (
    <div className={prefix}>
      <Card className={`${prefix}-card`}>
        <TypographyList className={`${prefix}-typography`} showShadow type="bulleted" content={formatData(gjbfDes)} />
      </Card>
    </div>
  )
}
export default InternationalCommunicationOverviewModal;