/**
 * 重点报道：重点主题
 */
import React from 'react';
import { Popover } from 'antd';
import Badge from '@/components/Badge';
import Card from '@/components/Card';

import './index.less'

const prefix = 'key-reports-key-theme-list-modal'

// TODO 接口对接
const KeyReportsKeyThemesListModal = ({ list, showDetail }) => {
  return (
    <div className={prefix}>
      {
        Array.isArray(list) && list.map((item, i) => <div
          key={item.title}>
          <Card
            showMore
            className={`${prefix}-card element-right-in`}
            style={{
              animationDelay: `${0.1 + 0.2 * (i)}s`
            }}
            onClick={() => showDetail(item)}
            title={<div key={item.title}
              className={`${prefix}-card-title`}
              onClick={() => showDetail(item)}
            >
              {
                item.title?.length > 14 ? <Popover placement="bottom" content={item.title} trigger="hover">
                  <span className={`${prefix}-card-title-content`}>{item.title}</span>
                </Popover> : <span className={`${prefix}-card-title-content`}>{item.title}</span>
              }
              <Badge>{item.count}条</Badge>
            </div>} />
        </div>)

      }
    </div>
  )
}
export default KeyReportsKeyThemesListModal;