import React, { useEffect, useState } from 'react';
import './ModalCard.less'

const prefix = "modal-card"
const ModalCard = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    console.log(typeof setIsModalOpen)
  });

  return (
    <div
      className={prefix}
    >
      <div className={`${prefix}-container`}>
        {props?.children || ""}
        {/* 展示分页信息 */}
      </div>
    </div>
  )
}
export default ModalCard;