/**
 * 其他媒体检测：新闻专题
 */

import React, { useEffect, useState } from 'react';
import NewsItem from './components/NewsItem';

import "./index.less";

const prefix = 'other-media-international-media-model'
const OtherMediaNewsInternationalMediaModal = ({
  g7Cont1,
  g7Cont2,
  g7Cont3,
  g7Cont4,
  g7Cont5,
  g7Cont6,
  g7Num
}) => {
  const [current, setCurrent] = useState("欧洲")
  const dataList = [
    {
      continent: "北美洲",
      content: g7Cont1,
      num: g7Num[0],
      bottom: '3.51rem',
      left: '2.2rem',
    },
    {
      continent: "欧洲",
      content: g7Cont2,
      num: g7Num[1],
      bottom: '3.8rem',
      left: '6.35rem',
    },
    {
      continent: "非洲",
      content: g7Cont5,
      num: g7Num[4],
      bottom: '2.33rem',
      left: '6.15rem',
    },
    {
      continent: "南美洲",
      content: g7Cont4,
      num: g7Num[3],
      bottom: '1.74rem',
      left: '3.63rem',
    },
    {
      continent: "亚洲",
      content: g7Cont3,
      num: g7Num[2],
      bottom: '3.27rem',
      left: '8.75rem',
    },
    {
      continent: "大洋洲",
      content: g7Cont6,
      num: g7Num[5],
      bottom: '1.37rem',
      left: '9.79rem',
    }
  ]
  return (
    <div className={prefix}>
      <div className={`${prefix}-container`}>
        {
          dataList.map(item => <NewsItem {...item} isShow={item.continent === current} onSelect={setCurrent} key={item.continent} />)
        }
      </div>
    </div>
  )
}
export default OtherMediaNewsInternationalMediaModal;