import React, { useState } from 'react';
import Tabs from '@/components/Tabs';
import Card from '@/components/Card';
import Badge from '@/components/Badge';
import TypographyList from '@/components/TypographyList';

import './index.less'

const CenterEnum = {
  CGTN: 1,
  AsianAfricanCenter: 2,
  EuropeAndLatinAmericaCenter: 3,
  ChineseLanguageCenter: 4,
}

const CenterType = {
  [CenterEnum.CGTN]: "CGTN",
  [CenterEnum.AsianAfricanCenter]: "亚非中心",
  [CenterEnum.EuropeAndLatinAmericaCenter]: "欧拉中心",
  [CenterEnum.ChineseLanguageCenter]: "华语中心",
}

const CenterList = [
  CenterEnum.CGTN,
  CenterEnum.AsianAfricanCenter,
  CenterEnum.EuropeAndLatinAmericaCenter,
  CenterEnum.ChineseLanguageCenter,
]

const getCenterNewsList = (tid, data) => {
  if (!Array.isArray(data)) return []

  let news = []
  let count = 0;
  data.map(item => {
    if (Number(item.tid) === tid) {
      news.push(item.title)
      count += Number(item.num)
    }
    return item
  })

  return {
    name: CenterType[tid],
    count,
    news,
  }
}

const getCenterNews = (data) => {
  const list = []
  Array.isArray(CenterList) && CenterList.map((item) => {
    list.push(getCenterNewsList(item, data))
    return item
  })
  return list
}

const prefix = 'international-communication-center-modal'
const InternationalCommunicationCenterModal = ({ list }) => {
  const [active, setActive] = useState("CGTN")

  return (
    <div className={prefix}>
      <Card className={`${prefix}-card`}>
        <Tabs
          className={`${prefix}-tab`}
          activeKey={active}
          onChange={setActive}
          keyInItem="name"
          childrenInItem="news"
          itemHeight='4.75rem'
          formatLabel={item => <>
            {item.name}
            <Badge>{item.count}条</Badge>
          </>}
          formatItem={item => <TypographyList className={`${prefix}-typography`} showShadow type="number" content={item} />}
          items={getCenterNews(list)}
        />
      </Card>
    </div>
  )
}
export default InternationalCommunicationCenterModal;