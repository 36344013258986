/**
 * 头条工程
 */
import React, { useEffect, useState } from 'react';
import ModalGroup from '@/components/ModalGroup'
import HeadlineProjectCMGModal from './components/HeadlineProjectCMGModal'
import HeadlineProjectOtherMediaModal from './components/HeadlineProjectOtherMediaModal'

const HeadlineProjectType = {
  CMG: 1,
  OTHER_MEDIA: 2,
}

const formatData = (list, type) => {
  if (!Array.isArray(list)) return []
  let data = []
  list.map(item => {
    const cont = item?.cont || []
    if (Number(item.type) === type && item.title !== "测试数据") {
      data.push({
        cont,
        type,
        title: item.title
      })
    }
  })
  return data
}

const HeadlineProjectModal = ({ onClose, data }) => {
  const [title, setTitle] = useState("总台头条")
  const [onShow, setOnShow] = useState("headline-project-cmg")

  const { impormain, impor03List } = data

  const items = [
    {
      id: "headline-project-cmg",
      title: "总台头条",
      element: <HeadlineProjectCMGModal {...impormain} info={formatData(impor03List, HeadlineProjectType.CMG)} />,
      width: "",
      height: "",
    },
    {
      id: "headline-project-other-media",
      title: "其他媒体头条",
      element: <HeadlineProjectOtherMediaModal
        {...impormain}
        info={formatData(impor03List, HeadlineProjectType.OTHER_MEDIA)}
      />,
      width: "",
      height: "",
    }
  ]

  const onChangeShow = (id) => {
    const item = items.find(item => item.id === id)
    setOnShow(id)
    item?.title && setTitle(item?.title)
  }

  return (
    <ModalGroup
      title={title}
      onShow={onShow}
      onClose={onClose}
      setOnShow={onChangeShow}
      items={items}
    />
  )
}
export default HeadlineProjectModal;