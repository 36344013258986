import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { getMonthDate, isDateTime } from '../utils/date';

import './DatePicker.less'

const prefix = 'date-picker'

const weekDayNames = ['日', '一', '二', '三', '四', '五', '六']

const checkIsMatch = (current, date) => `${Number(current.year)}/${Number(current.month)}/${Number(current.date)}` === `${Number(date.year)}/${Number(date.month)}/${Number(date.date)}`

const isEnabledDay = (startDay, endDay, currentDay) => startDay.isAfter(currentDay) || endDay.isBefore(currentDay)

const DatePickerHeader = ({ year, month, date, onChange }) => {

  const onChangeAddMonth = () => {
    const newCurrent = dayjs(`${year}-${month < 0 ? `0${month}` : month}-${date}`).add(1, 'month')
    const currentYear = newCurrent.year();
    const currentMonth = newCurrent.month() + 1;
    const currentDate = newCurrent.date();
    onChange?.(`${currentYear}-${currentMonth < 10 ? `0${currentMonth}` : currentMonth}-${currentDate}`)
  }

  const onChangeMinusMonth = () => {
    window.currentDate = dayjs(`${year}-${month < 0 ? `0${month}` : month}-${date}`)
    const newCurrent = dayjs(`${year}-${month < 0 ? `0${month}` : month}-${date}`).subtract(1, 'month')

    const currentYear = newCurrent.year();
    const currentMonth = newCurrent.month() + 1;
    const currentDate = newCurrent.date();
    onChange?.(`${currentYear}-${currentMonth < 10 ? `0${currentMonth}` : currentMonth}-${currentDate}`)
  }

  const onChangeAddYear = () => {
    const newCurrent = dayjs(`${year}-${month < 0 ? `0${month}` : month}-${date}`).add(1, 'year')
    const currentYear = newCurrent.year();
    const currentMonth = newCurrent.month() + 1;
    const currentDate = newCurrent.date();
    onChange?.(`${currentYear}-${currentMonth < 10 ? `0${currentMonth}` : currentMonth}-${currentDate}`)
  }

  const onChangeMinusYear = () => {
    const newCurrent = dayjs(`${year}-${month < 0 ? `0${month}` : month}-${date}`).subtract(1, 'year')
    const currentYear = newCurrent.year();
    const currentMonth = newCurrent.month() + 1;
    const currentDate = newCurrent.date();
    onChange?.(`${currentYear}-${currentMonth < 10 ? `0${currentMonth}` : currentMonth}-${currentDate}`)
  }

  return (<div className={`${prefix}-header`}>
    <div className={`${prefix}-header-group`}>
      <img
        alt=''
        className={`${prefix}-header-arrow`}
        src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/arrow-icon.png'
        onClick={onChangeMinusYear}
      />
      <span className={`${prefix}-header-date`}>{year}年</span>
      <img
        alt=''
        className={`${prefix}-header-arrow ${prefix}-header-arrow-rotate`}
        src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/arrow-icon.png'
        onClick={onChangeAddYear}
      />
    </div>
    <div className={`${prefix}-header-group`}>
      <img
        alt=''
        className={`${prefix}-header-arrow`}
        src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/arrow-icon.png'
        onClick={onChangeMinusMonth}
      />
      <span className={`${prefix}-header-date`}>{month < 10 ? `0${month}` : month}月</span>
      <img
        alt=''
        className={`${prefix}-header-arrow ${prefix}-header-arrow-rotate`}
        src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/arrow-icon.png'
        onClick={onChangeAddMonth}
      />
    </div>
  </div>
  )
}

const DatePickerContainer = ({ message, year, month, date, startDay, endDay, onChange }) => {
  const [dateInfo, setDateInfo] = useState({})
  const [today, setToday] = useState({})
  useEffect(() => {
    const info = getMonthDate(year, month)
    const today = new Date()
    setDateInfo(info)
    setToday({
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      date: today.getDate(),
    })
  }, [year, month, date])

  const { days } = dateInfo || {}

  const handleOnChange = (date) => {
    if(startDay && endDay && isEnabledDay(startDay, endDay, date)) {
      return message.open({
        className: 'app-message',
        icon: <></>,
        content: '请选择2021年01月01日到今天之间的日期',
      });
    }
    onChange?.(date);
  }

  return <div className={`${prefix}-container`}>
    {

      weekDayNames.map(item => <div className={classNames(`${prefix}-week-name`)} key={item}>{item}</div>)
    }
    {
      Array.isArray(days) && days.slice(0, 42).map(item =>
        <div
          className={classNames({
            [`${prefix}-date`]: true,
            [`${prefix}-current-month`]: Number(item.month) === Number(month) && !isEnabledDay(startDay, endDay, `${item.year}/${item.month}/${item.date}`),
            [`${prefix}-today`]: checkIsMatch(item, today) && !checkIsMatch(item, { year, month, date }),
            [`${prefix}-selected`]: checkIsMatch(item, { year, month, date }),
          })}
          onClick={() => handleOnChange(`${year}-${month}-${item.showDate < 10 ? `0${item.showDate}` : item.showDate}`)}
          key={item.date}
        >
          {item.showDate}
        </div>)
    }
  </div>
}

const DatePickerFooter = ({ onChange, onSubmit }) => {
  const changeToday = () => {
    const today = new Date()

    const day = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      date: today.getDate(),
    }
    onChange(`${day.year}-${day.month < 9 ? `0${day.month}` : day.month}-${day.date < 9 ? `0${day.date}` : day.date}`)
  }
  return <div className={`${prefix}-footer`}>
    <input placeholder='请输入时间' className={`${prefix}-footer-input`} onChange={(e) => onChange(e.target.value)} />
    <div className={`${prefix}-footer-current`} onClick={changeToday}>
      <img alt='' src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/date-icon.png' />
      <span>当前时间</span>
    </div>
    <div className={`${prefix}-footer-acitons`}>
      <button onClick={onSubmit}>确定</button>
    </div>
  </div>
}

const DatePicker = ({ message, value, onChange, startDay, endDay }) => {
  const [currentDay, setCurrentDay] = useState({}) // 当前选中时间
  const [currentMonth, setCurrentMonth] = useState(null) // 选项中的月
  const [currentYear, setCurrentYear] = useState(null) // 选中的年
  const [selectedDay, setSelectedDoday] = useState({}) // 当前选中时间

  useEffect(() => {
    const { year, month, date } = formatDate(value)

    setCurrentYear(year)
    setCurrentMonth(month)

    setCurrentDay({
      year,
      month: month < 10 ? `0${month}` : month,
      date: date < 10 ? `0${date}` : date,
    })

    setSelectedDoday({
      year,
      month: month < 10 ? `0${month}` : month,
      date: date < 10 ? `0${date}` : date,
    })
  }, [value])

  const formatDate = (value) => {
    const current = dayjs(value)
    const year = Number(current.year())
    const month = Number(current.month()) + 1 // rilizhong 
    const date = Number(current.date())

    return {
      year,
      month,
      date
    }
  }

  const changeInputDate = (value) => {
    if (!isDateTime(value)) return false

    const { year, month, date } = formatDate(dayjs(value).format('YYYY-MM-DD'))


    setCurrentYear(year)
    setCurrentMonth(month)
    setCurrentDay({
      year,
      month: month < 10 ? `0${month}` : month,
      date: date < 10 ? `0${date}` : date,
    })
  }

  const onSubmit = () => {
    const {
      year,
      month,
      date
    } = currentDay || {}

    const info = dayjs(`${year}-${month}-${date}`).format('YYYY-MM-DD')

    onChange?.(info)
  }

  return (
    <div className={prefix}>
      <DatePickerHeader year={currentYear} month={currentMonth} date={currentDay.date} onChange={changeInputDate} />
      <DatePickerContainer message={message} year={currentDay.year} month={currentDay.month} date={currentDay.date} selectedDay={selectedDay} onChange={changeInputDate} startDay={startDay} endDay={endDay} />
      <DatePickerFooter onChange={changeInputDate} onSubmit={onSubmit} />
    </div>
  )
}

export default DatePicker;