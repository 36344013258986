import React, { useEffect, useState } from 'react';
import { Popover, Carousel } from 'antd';
import { setTimeoutWithRAF } from '@/utils';

import './CCTVDataItem.less'

const prefix = 'cctv-data-item'

const DataItem = (props) => {

  if (!props.value || !props.type) {
    return <div className={`${prefix}-data-item`} style={{ width: props.width }}>-</div>
  }

  if (props.showPopover) {
    return <div className={`${prefix}-data-item`} style={{ width: props.width }}>
      <div className={`${prefix}-data-item-value`}>{props.value}</div><Popover placement="bottom" content={props.type} trigger="hover">
        <div className={`${prefix}-data-item-key`}>
          <span>{props.type}</span>
        </div>
      </Popover>
    </div>
  }
  return (
    <div className={`${prefix}-data-item`} style={{ width: props.width }}>
      {
        props.value && props.type ? <>
          <div className={`${prefix}-data-item-value`}>{props.value}</div>
          <div className={`${prefix}-data-item-key`}>
            <span>{props.type}</span>
          </div></> : null
      }
    </div>
  )
}

const rankIcon = [
  "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/rank-1st.png",
  "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/rank-2nd.png",
  "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/rank-3th.png",
]

const CCTVDataItem = ({ icon, share, pm, tvlist, index }) => {
  return (
    <div className={prefix}
      style={{
        animationDelay: `${2 + index * 0.2}s`
      }}
    >
      <div className={`${prefix}-img`}>
        <img alt='' src={icon} />
      </div>
      <DataItem width="1.4rem" type='收视份额' value={<>{share}<span>%</span></>} />
      <DataItem
        type='全国排名'
        width="1.80rem"
        value={rankIcon?.[pm - 1] ?
          <img className={`${prefix}-icon`} src={rankIcon[pm - 1]} /> : pm
        }
      />
      <div className={`${prefix}-group`}>
        <Carousel autoplay autoplaySpeed={3000}>
          {
            tvlist.map(({ key, name, percentage }, i) =>
              <DataItem
                key={key}
                width="2.8rem"
                type={name ? String(name).substring(0, name.length - 1) : ""}
                value={<>{percentage}<span>%</span></>}
                showPopover
              />
            )
          }
        </Carousel>
      </div>
    </div>
  )
}
export default CCTVDataItem;