import React from 'react';

import './Card.less'

const prefix = 'card'
const Card = ({ className, title, showMore, onClick, showMoreClick, children, style }) => {

  const handleClick = () => {
    typeof onClick === 'function' && onClick()
  }

  const handleShowMoreClick = () => {
    typeof showMoreClick === 'function' && showMoreClick()
  }
  // TODO 多行省略的问题
  return (
    <div className={`${prefix} ${className || ""}`} onClick={handleClick} style={style || {}}>
      {
        title ? <div className={`${prefix}-title`} onClick={handleShowMoreClick}>
          <div className={`${prefix}-title-content`}>{title}</div>
          {showMore ? <div className={`${prefix}-show-more-warp`}>
            <img alt='' className={`${prefix}-show-more`} src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/show-more-icon.png' />
          </div> : null}
        </div> : ""
      }
      {children ? <div className={`${prefix}-content`}>{children}</div> : null}
    </div>
  )
}
export default Card;