/**
 * 重点报道：其他主题列表
 */
import React from 'react';
import Card from '@/components/Card';

import './index.less'

const prefix = 'key-reports-other-themes-list-modal'

const renderTheme = (theme) => <span className={`${prefix}-theme`}>{theme}</span>

const KeyReportsOtherThemesListModal = ({ list, showDetail }) => {
  return (
    <div className={prefix}>
      {
        Array.isArray(list) && list.map(({ title, list }, i) => <Card
          showMore
          key={title}
          onClick={() => showDetail({ title, list })}
          className={`${prefix}-card element-right-in`}
          style={{
            animationDelay: `${0.1 + 0.1 *(i + 1)}s`
          }}
          title={<div className={`${prefix}-card-title`}>{renderTheme(`主题${i + 1}`)}{title}</div>}
        />)
      }
    </div>
  )
}
export default KeyReportsOtherThemesListModal;