/**
 * 重点报道：重点主题
 */
import React, { useState } from 'react';
import Card from '@/components/Card';
import Tabs from '@/components/Tabs';

import './index.less'

const prefix = 'key-reports-key-theme-modal'
const imgDomin = "https://cpec-impor.oss-cn-zhangjiakou.aliyuncs.com/"

const formatData = (list) => {
  if (!Array.isArray(list)) return []
  const data = []
  list.map(item => {
    data.push({
      icon: imgDomin + item.pdimg,
      source: item.pdname,
      extra: item.pdtitle,
    })
    return item
  })

  return data
}

const renderTitle = ({ title, icon }) => <div className={`${prefix}-card-title`}>
  <div className={`${prefix}-card-img`}>
    <img alt='' src={icon} />
  </div>
  <div className={`${prefix}-card-warp`}>
    <div className={`${prefix}-card-header`}>{title}</div>
  </div>
</div>

const renderContent = (info, index) => {
  const dataList = formatData(info?.pdcont || [])
  return <div key={index}>
    <p
      className={`${prefix}-content element-top-in`}
      style={{
        animationDelay: '1.2s'
      }}
    >{info?.titleContent}</p>
    <div className={`${prefix}-container`}>
      {
        Array.isArray(dataList) && dataList.map(item =>
          <Card
            key={item.source}
            className={`${prefix}-card element-right-in`}
            style={{
              animationDelay: '2.5s'
            }}
            title={
              renderTitle({
                ...item,
                title: item.source
              })}
          >
            {item.extra}
          </Card>)
      }
    </div>
  </div>
}

const KeyReportsKeyThemesModal = ({ content, list }) => {
  const [active, setActive] = useState()
  useState(() => {
    setActive(content?.[0]?.zdId || "")
  }, [list?.length])

  if (!Array.isArray(content) || content.length === 0) return null;

  if (content.length === 1) {
    return (<div className={prefix}>
      {renderContent(content?.[0] || [], 0)}
    </div>)
  }
  return (
    <div className={prefix}>
      <Tabs
        className={`${prefix}-tab`}
        activeKey={active}
        onChange={setActive}
        keyInItem="zdId"
        childrenInItem="pdcont"
        formatLabel={(item, value, index) => <>
          内容{index + 1}
        </>}
        formatItem={(item, info, i) => renderContent(info, i)}
        items={content}
      />
    </div>
  )
}
export default KeyReportsKeyThemesModal;