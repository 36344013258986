/**
 * 传播效果数据-CCTV
 */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Card from '@/components/Card';

import './index.less';
const prefix = "effect-hot-broadcasting-yangshipin-modal"

// YouSheBiaoTiHei
const EffectHotBroadcastingCCTVModal = ({ ftotle, tpeo, tfwl, tplay, ynDes, dateStr }) => {
   const dataList = [
      {
         icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/effect-yangshiping-uv.png",
         name: "日活跃用户数",
         count: tpeo,
      },
      {
         icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/effect-yangshiping-pv.png",
         name: "日访问量",
         count: tfwl,
      },
      {
         icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/effect-yangshipin-video-pv.png",
         name: "日视频播放总量",
         count: tplay,
      }
   ]
   return (
      <div className={prefix}>
         <div className={`${prefix}-data`}>
            <div className={`${prefix}-data-overview`}>
               <img className='element-top-in' alt='' src="https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/effect-yangshiping.png" />
               <div className={`${prefix}-data-overview-content element-top-in`}
                  style={{
                     animationDelay: '0.5s'
                  }}
               >
                  <div className={`${prefix}-data-overview-count`}>
                     {ftotle}<span>条</span>
                  </div>
                  <div className={`${prefix}-data-overview-title`}>
                     央视频日发稿总数
                  </div>
               </div>
            </div>
            <div className={`${prefix}-data-list element-right-in`}
               style={{
                  animationDelay: '1s'
               }}
            >
               {
                  Array.isArray(dataList) && dataList.map(item => <div className={`${prefix}-data-item`} key={item.name}>
                     <img alt='' src={item.icon} />
                     <div className={`${prefix}-data-item-content`}>
                        <div className={`${prefix}-data-item-name`}>{item.name}</div>
                        <div className={`${prefix}-data-item-count`}>{item.count}</div>
                     </div>
                  </div>)
               }
            </div>
            {
               dateStr && <div className={`${prefix}-data-extra`}>
                  数据日期：{dayjs(dateStr).format("YYYY年MM月DD日")}
               </div>
            }
         </div>
         <Card
            className={`${prefix}-card`}
            style={{
               animationDelay: '1.8s'
            }}
         >
            <div className={`${prefix}-intro`}>
               {ynDes || <span>暂无数据</span>}
            </div>
         </Card>
      </div>
   )
}
export default EffectHotBroadcastingCCTVModal;