import React, { useState } from "react";
import "./Carousel.less";

const prefix = "carousel"
const Carousel = ({ elements, onChange, setActive, onPrev, onNext }) => {
  const [current, setCurrent] = useState(0);

  const handlePrev = () => {
    if (current > -2) {
      setCurrent((current) => current - 1);
      onChange?.(current - 1);
    }
    onPrev?.();
  };

  const handleNext = () => {
    if (current < elements.length - 3) {
      setCurrent((current) => current + 1);
      onChange?.(current + 1);
    };
    onNext?.();
  };

  const sliderStyle = {
    transform: current > 0 ? `translateX(-${current * 1.7558}rem)` : `translateX(${(-current) * 1.7558}rem)`,
    transition: 'transform 0.5s ease-in-out',
    width: `${elements.length * 1.7558}rem`,
  };

  return (
    <div className="carousel">
      <div className={`${prefix}-conatiner`}>
        <div className="slider" style={sliderStyle}>
          {elements.map((element, index) => (
            <div key={index} className="slide element-top-in" onClick={() => {
              setCurrent(index - 2);
              setActive?.(index);
            }}>
              {element}
            </div>
          ))}
        </div>
      </div>
      <img src="https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/carousel-left.png" className="control prev" onClick={() => handlePrev(current)} />
      <img src="https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/carousel-right.png" className="control next" onClick={() => handleNext(current)} />
    </div>
  );
};

export default Carousel;