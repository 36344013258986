import React from 'react';
import Card from '@/components/Card'
import { drawNumber } from '@/utils';

import './index.less'

const prefix = 'international-communication-overivew-modal'
const InternationalCommunicationOverviewModal = ({ gtotle, gmt01, gmt02, gmt03, hwnTotle, whgzsTotle }) => {
  console.log('InternationalCommunicationOverviewModal', { gtotle, gmt01, gmt02, gmt03, hwnTotle, whgzsTotle })
  const dataList = [
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/international-new-media-icon.png",
      type: "新媒体",
      count: drawNumber(gmt01),
    },
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/international-tv-icon.png",
      type: "电视",
      count: drawNumber(gmt02),
    },
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/international-broadcast-icon.png",
      type: "广播",
      count: drawNumber(gmt03),
    },
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/international-reporter-station-icon.png",
      type: "海外记者站播发数",
      count: hwnTotle,
    },
    {
      icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/international-station-icon.png",
      type: "网红工作室播发数",
      count: whgzsTotle,
    },
  ]

  return (
    <div className={prefix}>
      <div className={`${prefix}-overview`}>
        <img className='element-top-in' alt='' src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/international-communication-overivew-icon.png' />
        <div className={`${prefix}-overview-count element-right-in`}
          style={{
            animationDelay: '0.8s'
          }}>
          {gtotle}
          <span>条</span>
        </div>
        <div className={`${prefix}-overview-title element-right-in`}
          style={{
            animationDelay: '0.8s'
          }}
        >国际传播播发数</div>
      </div>
      <Card className={`${prefix}-card element-top-in`}
          style={{
            animationDelay: '1.5s'
          }}>
        <div className={`${prefix}-data-list`}>
          {
            Array.isArray(dataList) ? dataList.map(item => <div className={`${prefix}-data-item`} key={item.type}>
              <img alt='' src={item.icon} />
              <div className={`${prefix}-content`}>
                <div className={`${prefix}-type`}>{item.type}</div>
                <div className={`${prefix}-count`}>
                  {item.count}
                  <span>条</span>
                </div>
              </div>
            </div>) : ""
          }
        </div>
      </Card>
    </div>
  )
}
export default InternationalCommunicationOverviewModal;