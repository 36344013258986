/**
 * 传播效果数据-总台央视份额
 */
import React, { useEffect, useRef } from 'react';
import { Progress } from 'antd';
import { Chart } from '@antv/g2';
import dayjs from 'dayjs';
import Card from '@/components/Card';
import { decimalsNumber } from '@/utils';
import CCTVDataItem from './components/CCTVDataItem';

import './index.less';
import classNames from 'classnames';
const prefix = "effect-hot-broadcasting-CCTV-modal"

// https://zbso.oss-cn-beijing.aliyuncs.com/CCTVLOGO/cctv08.png
const formatData = (list) => {
  if (!Array.isArray(list)) return []

  let data = []
  list.map(({ key, f, tvlist, tvimg, pm }, i) => {
    const list = [];
    Array.isArray(tvlist) && tvlist.map(item => {
      list.push({
        key: item.key,
        name: item.tvname,
        percentage: item.tvl
      })
    })
    data.push({
      key,
      share: f,
      pm: pm,
      rank: i + 1,
      icon: `https://zbso.oss-cn-beijing.aliyuncs.com/CCTVLOGO/${tvimg}.png`,
      tvlist: list
    })
  })
  return data
}


const formatWeekData = (weeks) => {
  if (!Array.isArray(weeks)) return {}
  const totle = []
  weeks.map(item => {
    const { impor01 = {} } = item
    totle.push(Number(impor01?.totle?.toString() || 0))
  })
  return {
    totle: totle.reverse(),
  }
}

const getMaxAndMin = (data) => {
  if (!Array.isArray(data)) return [0, 0];

  let max = Number.MIN_VALUE;
  let min = Number.MAX_VALUE;

  data.map(item => {
    if (item > max) {
      max = item;
    }
    if (item < min) {
      min = item;
    }
  })
  return {
    max: Math.ceil(max),
    min: Math.floor(min),
  }
}

const EffectHotBroadcastingCCTVModal = ({ totle, totledes = "", ovar, dateStr, weeks = [] }) => {
  const trendIcon = totledes.includes("下") ? "trend-down" : "trend-up"
  const rateClass = totledes.includes("下") ? `${prefix}-data-rate-down` : `${prefix}-data-up`
  const percentage = decimalsNumber(totledes)
  const weekData = formatWeekData(weeks)

  const rankList = formatData(ovar);

  const { min, max } = getMaxAndMin(weekData?.totle || [])
  const gap = max - min;

  return (
    <div className={prefix}>
      <div className={`${prefix}-data`}>
        <div className={`${prefix}-data-title`}>
          <img className='element-top-in' alt='' src="https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/EffectHotBroadcastingCCTVModal-icon.png" />
          <div
            className='element-right-in'
            style={{
              animationDelay: '0.8s'
            }}
          >
            总台央视总体份额
          </div>
        </div>
        {/* TODO 图表 */}
        <div
          className={`${prefix}-data-chart element-top-in`}
          style={{
            animationDelay: '1s'
          }}
        >
          <div className={`${prefix}-data-chart-container`}>
            {
              weekData?.totle?.map((item, i) => {
                console.log(item, min, gap, (item - min) / gap)
                return <div className={classNames(
                  `${prefix}-data-chart-progress`,
                  i > 5 ? `${prefix}-data-chart-progress-active` : null
                )} key={i}>
                  <Progress
                    percent={((item - min) / gap) * 100}
                    size={12}
                    strokeColor={i > 5 ? {
                      from: "#4DFFDF 0%",
                      to: "#4DA1FF 96.35%",
                      direction: "270deg"
                    } : {
                      from: "#4DFFDF 0%%",
                      to: "#4DA1FF 100%",
                      direction: "270deg"
                    }}
                    trailColor="#002953"
                    showInfo={false} />
                  <div className={`${prefix}-data-chart-progress-tooltip`}>
                    <div className='value'>{item}%</div>
                    <div className='key'>{dayjs(dateStr).subtract(6 - i, 'day').format('MM月DD日')}</div>
                  </div>
                </div>
              })
            }
          </div>
        </div>
        <div className={`${prefix}-data-warp`}
          style={{
            animationDelay: '1s'
          }}
        >
          <div className={`${prefix}-data-number`}>{totle}<span>%</span></div>
          <div className={`${prefix}-data-contrast`}>
            <div>比前一天</div>
            <img className={`${prefix}-data-trend`} src={`https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/${trendIcon}.png`} />
            <div className={rateClass}>{percentage}%</div>
          </div>
        </div>
        {
          dateStr && <div className={`${prefix}-data-extra`}>
            数据日期：{dayjs(dateStr).format("YYYY年MM月DD日")}
          </div>
        }
      </div>
      <Card className={`${prefix}-card`}>
        <div className={`${prefix}-intro`}>
          {
            Array.isArray(rankList) && rankList.map((item, i) => <CCTVDataItem key={`${item.key}${item.name}`} {...item} index={i} />)
          }
        </div>
      </Card>
    </div>
  )
}
export default EffectHotBroadcastingCCTVModal;