/**
 * 传播效果数据-中央广播电视总台
 */
import React from 'react';
import { Progress } from 'antd';
import Card from '@/components/Card';
import dayjs from 'dayjs';
import ModalCard from '@/components/ModalCard';
import { drawNumber } from '@/utils';

import './index.less';
const prefix = "effect-hot-broadcasting-cmg-modal"

// YouSheBiaoTiHei
const EffectHotBroadcastingCMGModal = ({ xmtTotle, xmtWx, xmtWb, xmtKhd, xmtQt, xmtDes, dateStr }) => {


   const dataList = [
      {
         icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/effect-cmg-weixin.png",
         name: "微信",
         count: drawNumber(xmtWx),
         countColor: "linear-gradient(90deg, #A6FFEF 0%, #FFFFFF 100%)",
         strokeColor: {
            from: "#4DFFDF 0%",
            to: "#4DA1FF 100%",
            direction: "270deg"
         }
      },
      {
         icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/effect-cmg-weibo.png",
         name: "微博",
         count: drawNumber(xmtWb),
         countColor: "linear-gradient(360deg, rgba(165, 224, 254, 1) 0, rgba(255, 255, 255, 1) 100%)",
         strokeColor: {
            from: "#1F50FF 0%",
            to: "#01AAFE 100%",
            direction: "90deg"
         }
      },
      {
         icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/effect-cmg-client.png",
         name: "客户端",
         count: drawNumber(xmtKhd),
         countColor: "linear-gradient(360deg, rgba(246, 192, 255, 1) 0, rgba(255, 255, 255, 1) 100%)",
         strokeColor: {
            from: "#7517F8 0%",
            to: "#E323FF 100%",
            direction: "90deg"
         }
      },
      {
         icon: "https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/effect-cmg-other.png",
         name: "其他",
         count: drawNumber(xmtQt),
         countColor: "linear-gradient(360deg, rgba(255, 213, 166, 1) 0, rgba(255, 255, 255, 1) 100%)",
         strokeColor: {
            from: "#FF5C5D 0%",
            to: "#FFA63F 100%",
            direction: "9deg"
         }
      }
   ]

   return (
      <ModalCard>
         <div className={prefix}>
            <div className={`${prefix}-data`}>
               <div className={`${prefix}-data-title`}>
                  <img alt='' src="https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/effect-cmg-icon.png" />
                  <div
                     className={`${prefix}-data-overview element-top-in`}
                     style={{
                        animationDelay: '0.5s'
                     }}
                  >
                     <div className={`${prefix}-data-count`}>
                        {xmtTotle}<span>条</span>
                     </div>
                     <div className={`${prefix}-data-name`}>
                        央视新闻新媒体日发稿总数
                     </div>
                  </div>
               </div>
               <div
                  className={`${prefix}-data-list element-right-in`}
                  style={{
                     animationDelay: '1s'
                  }}
               >
                  {
                     Array.isArray(dataList) && dataList.map(item =>
                        <div className={`${prefix}-data-item`} key={item.name}>
                           <img src={item.icon} />
                           <div className={`${prefix}-data-item-content`}>
                              <div className={`${prefix}-data-item-name`}>{item.name}<span style={{ backgroundImage: item.countColor }}>{item.count}</span>条</div>
                              <div className={`${prefix}-data-item-progress`}>
                                 <Progress percent={(item.count * 100) / 500} showInfo={false} strokeColor={item.strokeColor} />
                              </div>
                           </div>
                        </div>
                     )
                  }
               </div>
               {
                  dateStr && <div className={`${prefix}-data-extra`}>
                     数据日期：{dayjs(dateStr).format("YYYY年MM月DD日")}
                  </div>
               }
            </div>
            <Card
               className={`${prefix}-card`}
               style={{
                  animationDelay: '1.8s'
               }}
            >
               <div className={`${prefix}-intro`}>
                  {xmtDes || <span>暂无数据</span>}
               </div>
            </Card>
         </div>
      </ModalCard>
   )
}
export default EffectHotBroadcastingCMGModal;