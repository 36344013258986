/**
 * 国际传播
 */
import React, { useState } from 'react';
import ModalGroup from '@/components/ModalGroup'
import InternationalCommunicationOverviewModal from './components/InternationalCommunicationOverviewModal'
import InternationalCommunicationCenterModal from './components/InternationalCommunicationCenterModal'
import InternationalCommunicationThemeModal from './components/InternationalCommunicationThemeModal'

const InternationalCommunicationModal = ({ onClose, data }) => {
  const [title, setTitle] = useState("总体播发情况")
  const [onShow, setOnShow] = useState("international-communication-overview")

  const { impormain, impor4Art } = data
  const items = [
    {
      id: "international-communication-overview",
      title: "总体播发情况",
      element: <InternationalCommunicationOverviewModal {...impormain}  />,
    },
    {
      id: "international-communication-center",
      title: "各中心播发情况",
      element: <InternationalCommunicationCenterModal list={impor4Art} />,
    },
    {
      id: "international-communication-theme",
      title: "当日国际传播主要播发主题",
      element: <InternationalCommunicationThemeModal gjbfDes={impormain?.gjbfDes ?? ""} />,
      isDetail: !(impormain?.gjbfDes ?? "")
    }
  ]

  const onChangeShow = (id) => {
    const item = items.find(item => item.id === id)
    setOnShow(id)
    item?.title && setTitle(item?.title)
  }

  return (
    <ModalGroup
      title={title}
      onShow={onShow}
      onClose={onClose}
      setOnShow={onChangeShow}
      items={items}
    />
  )
}
export default InternationalCommunicationModal;