import React, { useEffect, useState } from 'react';
// import html2canvas from 'html2canvas';
import { Modal as AntdModal } from 'antd';
import classNames from 'classnames';

import './ModalGroup.less'

const prefix = 'modal-group';

const ModalGroup = ({ className, isDetail, hidePreview, items = [], title, prePage, onShow, showGradualChange, onReturn, setOnShow, width = '1318px', top = "84px" }) => {
  return (
    <div className={prefix}>
      <AntdModal
        className={classNames(`${prefix}-modal`, isDetail ? `${prefix}-modal-detail` : '', className)}
        title={isDetail ? <div
          className={`${prefix}-modal-detail-header`}
          data-text={title}
          onClick={() => onReturn?.(prePage)} >
          <img className='common' src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/modal-detail-return.png' />
          <img className='hover' src='https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/modal-detail-return-hover.png' />
          {title}
        </div> : <div className={`${prefix}-header`} data-text={title}>{title}</div>}
        open={true}
        mask={false}
        transitionName=''
        footer={null}
        closable={false}
        style={{
          top,
          width
        }}
      >
        <div className={`${prefix}-container`}>
          <div className={`${prefix}-warp`}>
            {
              Array.isArray(items) && items.map(item => {
                if (item.isEmpty) return null
                return <div id={item.id} className={`${prefix}-container-banner`} style={{ display: item.id === onShow ? "block" : "none" }} key={item.id}>{
                  item.id === onShow ? item?.element : null}</div>
              })
            }
          </div>
          {/* 展示分页信息 */}
          {
            showGradualChange ? <div className={`${prefix}-gradual-change`}></div> : null
          }
        </div>
      </AntdModal>
      {
        !hidePreview && !isDetail ?
          <div className={`${prefix}-preview`}>
            {Array.isArray(items) && items.map(item => !item.isHidden && !item?.isEmpty && !item?.isDetail ?
              <div
                className={
                  classNames(
                    item.id === onShow ?
                      `${prefix}-preview-active-item` : `${prefix}-preview-item`)
                }
                key={item.id}
                onClick={() => setOnShow?.(item.id)}
                style={{
                  backgroundImage: `url(${`https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/${item.id}.png`})`
                }}
              /> : '')}
          </div> : null
      }
    </div>
  )
}
export default ModalGroup;