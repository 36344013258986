import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import './ScrollingNumber.less'
import { setTimeoutWithRAF } from '../utils';

const prefix = 'scrolling-number'

const numbers = Array.from(new Array(10).keys())

const ScrollingNumber = ({ currentNumber = 2 }) => {
  const warpRef = useRef()
  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    changeNumber(currentNumber, warpRef);
  }, [currentNumber])

  const changeNumber = (number, element) => {
    const current = number === 0 ? numbers.length : number;
    const warpElement = element?.current || {}
    const warpHeight = element?.current.clientHeight || 0;
    const itemHeight = warpHeight / (numbers.length + 1);
    warpElement?.style.setProperty('--duration', '3.5s');
    warpElement?.style.setProperty('--translate', `-${itemHeight * current}px`);
    warpElement?.style.setProperty('--delay', '0.5s');
  }

  return (
    <div className={prefix}>
      <div className={`${prefix}-container`}>
        <div className={`${prefix}-warp`} ref={warpRef}>
          {
            [...numbers, 0].map((item, i) => <div
              className={
                classNames(`
                ${prefix}-item`,
                  item === currentNumber ? `${prefix}-fade-in` : `${prefix}-fade-out`,
                  isShow ? `${prefix}-loading` : ""
                )}
              key={i}
            >
              {item}
            </div>)
          }
        </div>
      </div>
    </div>
  )
}
export default ScrollingNumber;